import React from 'react';
import SearchInput from 'src/components/form-elements/SearchInput';

const VehiclesSearch = ({
  placeholder,
  options,
  values,
  setFieldValue,
  fieldName,
  lotFieldName,
}) => {
  const onChangeHandler = change => {
    if (change.length > 0) {
      setFieldValue(fieldName, change[0].title);
      setFieldValue(lotFieldName, change[0].lotNumber + '');
    }
  };

  const onInputChangeHandler = change => {
    setFieldValue(fieldName, change);
  };

  return (
    <SearchInput
      placeholder={placeholder}
      options={options}
      values={values}
      fieldName={fieldName}
      onInputChange={onInputChangeHandler}
      onChange={onChangeHandler}
      labelKey="title"
    />
  );
};

export default VehiclesSearch;
