import React from 'react';
import BiddingMethodItem from './BiddingMethodItem';
import styles from './SelectCard.module.scss';

export default props => {
  const { auctions, isKiosk } = props;
  const priceRange = getAveragePricePerMethod(auctions['In-Person Bidding']);
  return (
    <div>
      <div className={styles.auctionSelectionHeading}>
        Select a Bidding Method
      </div>

      <div
        className={`${styles.gridContainerMethods} ${
          isKiosk ? styles.gridKioskContainerMethods : ''
        }`}
      >
        <BiddingMethodItem
          {...props}
          title="Online Bidding"
          price={'0'}
          visible={!auctions['Online Bidding'].length > 0}
        />

        <BiddingMethodItem
          {...props}
          title="Telephone & Absentee Bidding"
          price={'0'}
          visible={!auctions['Telephone & Absentee Bidding'].length > 0}
        />

        <BiddingMethodItem
          {...props}
          title="In-Person Bidding"
          priceRange={`${priceRange.min} - ${priceRange.max}`}
          visible={!auctions['In-Person Bidding'].length > 0}
        />
      </div>
    </div>
  );

  function getAveragePricePerMethod(auctions) {
    const prices = [];
    const defaultBuyerStatus = 'Standard';

    auctions.forEach(auction => {
      if (props.values.buyerStatus) {
        const basicOffer =
          auction.offers.find(
            offer =>
              parseInt(offer.eligibleQuantity) === 1 &&
              offer.eligibleCustomerType.some(
                type => type.title === props.values.buyerStatus
              )
          ) ??
          auction.offers.find(
            offer =>
              parseInt(offer.eligibleQuantity) === 1 &&
              offer.eligibleCustomerType.some(
                type => type.title === defaultBuyerStatus
              )
          );
        if (basicOffer) prices.push(basicOffer.price);
        auction.offers.forEach(offer => {
          if (
            parseInt(offer.eligibleQuantity) > 1 &&
            offer.eligibleCustomerType.some(
              type => type.title === props.values.buyerStatus
            )
          ) {
            prices.push(offer.price);
          }
        });
      }
    });

    if (prices.length > 0) {
      return {
        min: Math.min(...prices)
          .toString()
          .replace('INFINITY', 0),
        max: Math.max(...prices)
          .toString()
          .replace('-INFINITY', 0),
      };
    } else {
      return {
        min: 0,
        max: 0,
      };
    }
  }
};
