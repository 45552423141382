export const steps = {
  USER_INFO: 'userInfo',
  AUCTIONS_SELECTION: 'auctionsSelection',
  CONDITION_OF_SALE: 'conditionOfSale',
  UPLOADING_PERSONAL_DOCUMENTS: 'uploadingDocuments',
  FINANCIAL_DATA: 'financialData',
  PAYMENT: 'payment',
  TELEPHONE_BIDDING_DETAILS: 'telephoneBiddingDetails',
  // kiosk extra steps
  KIOSK_CONFIRMATION: 'kioskConfirmation',
  EMAIL: 'email',
  KIOSK_PAYMENT: `kioskPayment`,
};
export const stepperStep = {
  ['userInfo']: 'Register To Bid',
  ['auctionsSelection']: 'Select Biding Method & Auctions',
  ['conditionOfSale']: 'Conditions of Sale',
  ['payment']: 'In-Person Bidding Registeration',
  ['financialData']: 'Financial References',
  ['telephoneBiddingDetails']: 'Telephone Bidding Details',
};
export const activeSignedStepperSteps = [
  'userInfo',
  'auctionsSelection',

  'financialData',
  'payment',
];
export const stepperSteps = [
  'userInfo',
  'auctionsSelection',
  'conditionOfSale',

  'financialData',
  'payment',
];
// FINANCIAL_DATA: 'financialData',
// PAYMENT: 'payment',
// TELEPHONE_BIDDING_DETAILS: 'telephoneBiddingDetails',
// // kiosk extra steps
// KIOSK_CONFIRMATION: 'kioskConfirmation',
// EMAIL: 'email',
// KIOSK_PAYMENT: `kioskPayment`,
