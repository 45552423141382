import { gql } from '@apollo/client';

export const GET_ASSET_PRESIGNED_UPLOAD_URLS = gql`
  mutation CreateUploadURL($signedAssetInput: SignedAssetInput) {
    CreateUploadURL(signedAssetInput: $signedAssetInput) {
      uploadURL
      fileId
    }
  }
`;

export const GET_ASSET_PRESIGNED_DOWNLOAD_URL = gql`
  query GetDownloadURL($key: String) {
    GetDownloadURL(key: $key) {
      url
    }
  }
`;

export const GET_ADMIN_ASSET_PRESIGNED_UPLOAD_URLS = gql`
  mutation CreateUploadURL(
    $signedAssetInput: SignedAssetInput
    $userSalesforceId: String
  ) {
    CreateAdminUploadURL(
      signedAssetInput: $signedAssetInput
      userSalesforceId: $userSalesforceId
    ) {
      uploadURL
      fileId
    }
  }
`;
