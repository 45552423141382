import React, { useState, useEffect } from 'react';
import SEO from 'components/seo';
import { Form, Formik } from 'formik';
import { SCHEMA } from 'components/utils/profileFormUtils';
import Layout from 'components/Layout';
import ProfileForm from 'features/account/profileForm';
import ProfileHeader from 'features/account/profileHeader';
import {
  GET_PROFILE_DATA,
  UPDATE_USER_PROFILE,
} from 'services/graphql/queries/profile';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import * as moment from 'moment';
import {
  useAuthenticatedQuery,
  useAuthenticatedMutation,
} from 'services/graphql/hooks';
import ChangeEmail from 'features/account/ChangeEmail';
import ChangePassword from 'features/account/ChangePassword';
import ConfirmAlert from 'components/ConfirmAlert';
import { UPDATE_MESSAGES } from 'utils/UpdateMessages';
import styles from './profile.module.scss';

const Profile = () => {
  const { loading, data, error } = useAuthenticatedQuery(GET_PROFILE_DATA, {
    fetchPolicy: 'network-only',
  });
  const [
    updateProfile,
    { error: updateError, loading: updateLoading },
  ] = useAuthenticatedMutation(UPDATE_USER_PROFILE);

  const [showSuccess, setShowSuccess] = useState(false);
  const [profile, setProfileData] = useState();

  useEffect(() => {
    if (data?.profile) setProfileData(data.profile);
  }, [data]);

  const showSuccessMessage = () => {
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 7000);
  };
  return (
    <Layout>
      <SEO title="Profile" />
      {error && <Alert color="danger" msg={error.message} />}
      {updateError && <Alert color="danger" msg={updateError.message} />}

      {loading || !profile ? (
        <Spinner />
      ) : (
        !error && (
          <div className={styles.profile}>
            <div className={styles.body}>
              {updateLoading && <Spinner />}
              <ProfileHeader />
              <div>
                <Formik
                  validateOnMount
                  initialValues={{
                    ...profile,
                    birthdate: profile.birthdate
                      ? moment
                          .utc(new Date(`${profile.birthdate}`))
                          .format('MM/DD/YYYY')
                      : '',
                  }}
                  validationSchema={SCHEMA}
                  onSubmit={(values, { resetForm }) => {
                    const profileData = { ...values };
                    if (values.birthdate)
                      profileData.birthdate = moment(values.birthdate).format(
                        'YYYY-MM-DD'
                      );
                    delete profileData.__typename;
                    delete profileData.email;
                    return updateProfile({
                      variables: {
                        user: profileData,
                      },
                    }).then(() => {
                      showSuccessMessage();
                      setProfileData(values);
                      resetForm({ values: values });
                    });
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    isValid,
                    dirty,
                    isSubmitting,
                  }) => {
                    return (
                      <Form>
                        <ProfileForm
                          values={values}
                          setFieldValue={setFieldValue}
                          disabled={!(isValid && dirty) || isSubmitting}
                        />
                      </Form>
                    );
                  }}
                </Formik>
                <div className={styles.profileForm}>
                  <div className="row">
                    <div className={`col-sm-12 col-md-6`}>
                      <ChangeEmail
                        profile={profile}
                        updateProfile={updateProfile}
                      />
                    </div>
                    <div className={`col-sm-12 col-md-6`}>
                      <ChangePassword profile={profile} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ConfirmAlert
              message={UPDATE_MESSAGES['general']}
              show={showSuccess}
            />
          </div>
        )
      )}
    </Layout>
  );
};

export default Profile;
