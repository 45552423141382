import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import ProfileIcon from 'svg/ProfileIcon';
import BookmarkIcon from 'svg/BookmarkIcon';
import ProfilePaddleIcon from 'svg/ProfilePaddleIcon';
import styles from './header.module.scss';
import HeartIcon from 'svg/HeartIcon';
import RecentlyViewedIcon from '../../../../static/svg/RecentlyViewedIcon';
import CommunicationPreferencesIcon from '../../../../static/svg/communicationPreferences';
import LogOutIcon from '../../../../static/svg/LogOutIcon';
import Button from 'components/Button';
import { clearAMSession } from 'utils/session';
import { useAuth0 } from '@auth0/auth0-react';

const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { logout } = useAuth0();
  const isActive = tab => {
    const activeTab = {
      '/auth/profile': 'profile',
      '/auth/following': 'following',
      '/auth/registration-history': 'registrationHistory',
      '/auth/recently-viewed': 'recentlyViewed',
    };
    return tab === activeTab[pathname];
  };

  return (
    <div className={styles.accountHeader}>
      <Link to="/auth/profile" activeClassName={styles.active}>
        <ProfileIcon color={'#1C5BAE'} solid={!isActive('profile')} />
        Profile
      </Link>
      <Link to="/auth/following" activeClassName={styles.active}>
        <HeartIcon color={'#1C5BAE'} width={'20px'} height={'18px'} />
        My Favorites
      </Link>
      <Link to="/auth/registration-history" activeClassName={styles.active}>
        <ProfilePaddleIcon color={'#1C5BAE'} />
        Registration History
      </Link>
      {/* <Link to="/auth/recently-viewed" activeClassName={styles.active}>
        <RecentlyViewedIcon />
        Recently Viewed
      </Link> */}
      {/* <Link activeClassName={styles.active}>
        <CommunicationPreferencesIcon />
        Communication Preferences
      </Link> */}
      <Button
        handleClick={async () => {
          await clearAMSession();
          return logout({ returnTo: window.location.origin });
        }}
        type={`${styles.logOutButton}`}
      >
        {/* <FontAwesomeIcon
                  className={styles.subMenuIcon}
                  icon={row.subMenuIcon}
                /> */}
        <LogOutIcon />
        Logout
      </Button>
    </div>
  );
};

export default Header;
