import React from 'react';
import Telephone from './Telephone';
import Footer from '../Footer';
import { useQuery } from '@apollo/client';
import { GET_AUCTION_LOTS } from 'services/graphql/queries/register-to-bid';

export default ({
  setFieldValue,
  values,
  auctions,
  isValid,
  setStep,
  handleSubmit,
  dispatchChevronStep,
  dispatchPrevSteps,
  isKiosk,
  handleStartOverButton,
}) => {
  const { loading, error, data } = useQuery(GET_AUCTION_LOTS, {
    variables: { auctionIds: values.auction.join() },
  });
  const lots = data?.getAuctionVehicles || [];

  async function submit() {
    if (!isValid) return;
    values.telephoneBiddingLots = getTelephoneBiddingLots(
      values,
      auctions,
      lots
    );
    await transitionToNextStep();
  }

  async function transitionToNextStep() {
    await handleSubmit(values);
  }

  async function transitionToPrevStep() {
    dispatchChevronStep('back');
    const prevStep = dispatchPrevSteps({ type: 'pop' });
    setStep(prevStep);
  }

  return (
    <>
      <div className="user-input-form step-6">
        <Telephone
          values={values}
          setFieldValue={setFieldValue}
          auctions={auctions}
          lots={lots}
          error={error}
          loading={loading}
        />
      </div>
      <Footer
        handleNext={() => submit()}
        handleBack={() => transitionToPrevStep()}
        isValid={isValid}
        submitButtonText={isKiosk ? 'Next' : 'Submit'}
        isKiosk={isKiosk}
        handleStartOverButton={handleStartOverButton}
      />
    </>
  );
};

const getTelephoneBiddingLots = (values, auctions, lots) => {
  const biddingLots = [];
  values.auction.forEach((auctionId, index) => {
    if (values[`description${index}`] || values[`lotNumber${index}`]) {
      const biddingLot = {
        lotNumber: values[`lotNumber${index}`] || '',
        description: values[`description${index}`] || '',
        maximumBid: values[`maximumBid${index}`] || '',
      };
      addVehicleInterstFields(biddingLot, auctionId, auctions, lots);
      biddingLots.push(biddingLot);
    }

    if (
      values[`description${index}${index}`] ||
      values[`lotNumber${index}${index}`]
    ) {
      const biddingLot = {
        lotNumber: values[`lotNumber${index}${index}`] || '',
        description: values[`description${index}${index}`] || '',
        maximumBid: values[`maximumBid${index}${index}`] || '',
      };

      addVehicleInterstFields(biddingLot, auctionId, auctions, lots);
      biddingLots.push(biddingLot);
    }
  });

  return biddingLots;
};

const addVehicleInterstFields = (biddingLot, auctionId, auctions, lots) => {
  if (!biddingLot.lotNumber) return;
  const auction = auctions.find(a => a.objectID === auctionId) || {};
  const lot = lots.find(
    l =>
      l.auctionId === auctionId &&
      l.lotNumber === parseInt(biddingLot.lotNumber)
  );
  if (lot) {
    biddingLot.salesForceId = lot.salesForceId;
    biddingLot.auctionCampaigId = auction.campaignId;
  }
};
