import React, { useState } from 'react';
import {
  Modal as StrapModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import './modal.scss';
import Button from 'components/Button';

const ModalWithButtons = ({
  title,
  bodyText,
  isOpen = false,
  className = 'save-modal',
  disableToggling = false,
  buttons,
}) => {
  const [modal, toggleModal] = useState(isOpen);

  return (
    <div className="failureModal-modal">
      <StrapModal
        isOpen={modal}
        toggle={!disableToggling ? () => toggleModal(!modal) : null}
        backdrop={'static'}
        centered={true}
        className={className}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={!disableToggling ? () => toggleModal(!modal) : null}
          />
          <ModalBody>
            <div>
              <h3 className="modal-title">{title}</h3>
            </div>
            <div className="modal-content">
              <div dangerouslySetInnerHTML={{ __html: bodyText }} />
            </div>
          </ModalBody>
          <ModalFooter className="modal-footer">
            {buttons?.length
              ? buttons.map((button, index) => (
                  <Button
                    key={'modalWithButtons' + index}
                    type="app-transparent-blue-button"
                    handleClick={button.handleSubmit}
                  >
                    {button.text}
                  </Button>
                ))
              : ''}
          </ModalFooter>
        </div>
      </StrapModal>
    </div>
  );
};

export default ModalWithButtons;
