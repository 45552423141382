export const convertBase64ToFile = (image, fileName) => {
  let imageBase = image.split(','),
    mime = imageBase[0].match(/:(.*?);/)[1],
    bstr = atob(imageBase[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${fileName}.${mime.split('/')[1]}`, { type: mime });
};

export const convertFileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};
