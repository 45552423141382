import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import AdminRoute from 'components/AdminRoute';
import KioskAdminRoute from 'components/KioskAdminRoute';
import Login from 'components/Auth/login';
import Unverified from 'features/Auth/unverified';
import OnlineRegistration from 'privatePages/OnlineRegistration';
import Following from 'privatePages/Following';
import Profile from 'privatePages/Profile';
import Checkout from 'privatePages/Checkout';
import LandingPage from 'adminPages/Kiosk/LandingPage';
import Files from 'privatePages/Files';
import RegistrationHistory from 'privatePages/RegistrationHistory';
import SEO from 'components/seo';
import LinkKiosk from 'adminPages/Kiosk/LinkKiosk';
import { RecentlyViewed } from 'privatePages/recently-viewed';
import CheckoutUk from 'privatePages/Checkout-uk';

const App = () => (
  <>
    <SEO title="Auth" />
    <Router>
      <PrivateRoute
        path="/auth/online-registration"
        component={OnlineRegistration}
      />
      <PrivateRoute path="/auth/following" component={Following} />
      <PrivateRoute path="/auth/recently-viewed" component={RecentlyViewed} />
      <PrivateRoute path="/auth/profile" component={Profile} />
      <PrivateRoute
        path="/auth/registration-history"
        component={RegistrationHistory}
      />
      <AdminRoute path="/auth/files/:id" component={Files} />
      <KioskAdminRoute path="/auth/kiosk" component={LandingPage} />
      <KioskAdminRoute path="/auth/link-kiosk" component={LinkKiosk} />
      <KioskAdminRoute
        path="/auth/kiosk/register-online"
        component={OnlineRegistration}
      />
      <Unverified path="/auth/unverified" />
      <Login path="/auth/login" />
      <Checkout path="/auth/checkout" />
      <CheckoutUk path="/auth/checkout-uk" />
    </Router>
  </>
);
export default App;
