import * as Yup from 'yup';

const INITIAL_VALUES = {
  acceptPrivacyPolicy: false,
};

const VALIDATION_SCHEMA = Yup.object().shape({
  acceptPrivacyPolicy: Yup.boolean().oneOf(
    [true],
    'Please Accept Terms and Conditions'
  ),
});

export { INITIAL_VALUES, VALIDATION_SCHEMA };
