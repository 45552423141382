import _ from 'lodash';

export const computePrice = (values, auctions) => {
  const selectedAuctions = [];
  if (values.auction.length > 0) {
    values.auction.forEach(auction => {
      selectedAuctions.push(auctions.find(x => x.objectID === auction));
    });
  }

  const eligibleOffers = getEligibleOffers(values, selectedAuctions);
  const { price, pricesBreakdown } = applyDiscount(
    eligibleOffers,
    selectedAuctions.length
  );
  return {
    price,
    pricesBreakdown,
  };
};
const getEligibleOffers = (values, selectedAuctions) => {
  //object to save offers for quantities > 1, key => quantity value => array with all offers for this quantity
  const discountOffers = {};
  const basicOffers = [];
  const maxQuantity = selectedAuctions.length;
  if (values.buyerStatus) {
    selectedAuctions.forEach(auction => {
      // get offers with quantity 1 for user buyer status
      // if not found get the offer for the standard user type
      const basicOffer =
        auction.offers.find(
          offer =>
            parseInt(offer.eligibleQuantity) === 1 &&
            offer.eligibleCustomerType.some(
              type => type.title === values.buyerStatus
            )
        ) ??
        auction.offers.find(
          offer =>
            parseInt(offer.eligibleQuantity) === 1 &&
            offer.eligibleCustomerType.some(type => type.title === 'Standard')
        );

      if (basicOffer) basicOffers.push(formatOffer(basicOffer, auction));

      // get all offers quantity > 1 for user buyer status
      auction.offers.forEach(offer => {
        if (
          parseInt(offer.eligibleQuantity) > 1 &&
          parseInt(offer.eligibleQuantity) <= maxQuantity &&
          offer.eligibleCustomerType.some(
            type => type.title === values.buyerStatus
          )
        ) {
          (
            discountOffers[offer.eligibleQuantity] ||
            (discountOffers[offer.eligibleQuantity] = [])
          ).push(formatOffer(offer, auction));
        }
      });
    });
  }
  return { basicOffers, discountOffers };
};

const formatOffer = (offer, auction) => {
  return {
    auctionID: auction.objectID,
    auctionName: auction.name,
    id: offer.id,
    price: offer.price,
    eligibleQuantity: offer.eligibleQuantity,
    customerType: offer.eligibleCustomerType,
    subEvents: auction.subEvents,
  };
};

const applyDiscount = (offers, maxQuantity) => {
  const { basicOffers, discountOffers } = offers;
  let price = 0;
  const calculatedAuctions = [];
  const pricesBreakdown = [];

  // loop on offers with bigger quantity first
  for (let i = maxQuantity; i > 1; i--) {
    if (discountOffers[i]) {
      const quantityOffers = discountOffers[i];

      // group offers by id
      const groupedByOfferId = _.groupBy(quantityOffers, 'id');

      Object.keys(groupedByOfferId).forEach(key => {
        if (groupedByOfferId[key].length >= i) {
          let tempOffers = [];
          for (const offer of groupedByOfferId[key]) {
            if (!calculatedAuctions.includes(offer.auctionID)) {
              tempOffers.push(offer);
            }
            if (tempOffers.length === i) {
              price += groupedByOfferId[key][0].price;

              calculatedAuctions.push(
                ...tempOffers.map(item => item.auctionID)
              );
              pricesBreakdown.push(tempOffers);
              tempOffers = [];
            }
          }
        }
      });
    }
  }
  basicOffers.forEach(offer => {
    if (!calculatedAuctions.includes(offer.auctionID)) {
      price += offer.price;
      pricesBreakdown.push([offer]);
    }
  });

  return {
    price,
    pricesBreakdown,
  };
};

export const checkoutSubmit = async (
  values,
  setLoading,
  setStripeError,
  stripe,
  elements,
  CardNumberElement,
  CreateIntent,
  inPersonCost
) => {
  const {
    email,
    firstName0,
    lastName0,
    aboutYouAddress01,
    aboutYouAddress02,
    city0,
    state0,
    zipcode0,
    country0,
  } = values;
  const checkoutData = {
    amount: +(inPersonCost * 100).toFixed(),
    receiptEmail: email,
    shipping: {
      name: `${firstName0} ${lastName0}`,
      line1: aboutYouAddress01,
      line2: aboutYouAddress02,
      city: city0,
      country: country0,
      postalCode: zipcode0,
      state: state0,
    },
  };

  try {
    setLoading(true);
    return await CreateIntent({
      variables: { checkoutData },
    }).then(async res => {
      const clientSecret = res.data.CreateIntent.clientSecret;
      return confirmPayment(
        clientSecret,
        setLoading,
        setStripeError,
        stripe,
        elements,
        CardNumberElement
      ).then(async response => {
        setLoading(false);
      });
    });
  } catch (error) {
    setStripeError(error);
    setLoading(false);
    throw Error(error);
  }
};

const confirmPayment = async (
  clientSecret,
  setLoading,
  setStripeError,
  stripe,
  elements,
  CardNumberElement
) => {
  setLoading(true);
  setStripeError(false);
  const payload = await stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card: elements.getElement(CardNumberElement),
    },
  });
  setLoading(false);
  if (payload.error) {
    setStripeError(payload.error.message);
    throw Error(payload.error.message);
  }
  return payload;
};
