import React from 'react';
import Condition from './condition';
import './step.scss';
import Footer from '../Footer';
import { steps } from 'utils/registrationStepsConstants';
import { BIDDING_METHODS } from 'src/utils/auctionTypes';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import { computePrice } from 'privatePages/OnlineRegistration/checkout';

export default ({
  isValid,
  setStep,
  values,
  dispatchChevronStep,
  isPersonalDateOutdated,
  isFinancialDateOutdated,
  handleSubmit,
  dispatchPrevSteps,
  isKiosk,
  auctions,
  handleStartOverButton,
  nextButtonRef,
}) => {
  const conditionsOfSale = values.conditionsOfSale;
  const slugs = useWebPagesSlugs();
  async function submit() {
    if (!isValid) return;
    values['conditionsOfSaleSubmitTimeStamp'] = new Date();
    await transitionToNextStep();
  }

  async function transitionToNextStep() {
    dispatchChevronStep('next');
    dispatchPrevSteps({ type: 'push', payload: steps.CONDITION_OF_SALE });
    // if (isPersonalDateOutdated) setStep(steps.UPLOADING_PERSONAL_DOCUMENTS);
    if (isFinancialDateOutdated && !isKiosk) setStep(steps.FINANCIAL_DATA);
    else if (values.biddingMethod === BIDDING_METHODS.IN_PERSON_BIDDING) {
      if (isKiosk) {
        const { price } = computePrice(values, auctions);
        if (price) setStep(steps.KIOSK_PAYMENT);
        else await handleSubmit(values);
      } else {
        setStep(steps.PAYMENT);
      }
    } else if (values.biddingMethod === BIDDING_METHODS.TELEPHONE_BIDDING)
      setStep(steps.TELEPHONE_BIDDING_DETAILS);
    else {
      await handleSubmit(values);
    }
  }

  function transitionToPrevStep() {
    dispatchChevronStep('back');
    const prevStep = dispatchPrevSteps({ type: 'pop' });
    setStep(prevStep);
  }

  return (
    <>
      <div className="user-input-form">
        <h1 className="heading">Conditions of Sale</h1>
        {conditionsOfSale.length > 1 ? (
          <Condition
            nextButtonRef={nextButtonRef}
            isKiosk={isKiosk}
            country={'US'}
            slugs={slugs}
          />
        ) : conditionsOfSale[0] === 'US Conditions of sale' ? (
          <Condition
            nextButtonRef={nextButtonRef}
            isKiosk={isKiosk}
            country={'US'}
            slugs={slugs}
          />
        ) : (
          <Condition
            nextButtonRef={nextButtonRef}
            isKiosk={isKiosk}
            country={'UK'}
            slugs={slugs}
          />
        )}
      </div>
      <Footer
        handleNext={async () => await submit()}
        handleBack={() => transitionToPrevStep()}
        isValid={isValid}
        isKiosk={isKiosk}
        submitButtonText={
          // !isPersonalDateOutdated &&
          // ((!isFinancialDateOutdated && !isKiosk) || isKiosk) &&
          // values.biddingMethod === BIDDING_METHODS.ONLINE_BIDDING
          //   ? 'Submit' :
          'Next'
        }
        handleStartOverButton={handleStartOverButton}
        nextRef={nextButtonRef}
      />
    </>
  );
};
