import React, { useRef, useState, useCallback } from 'react';
import Button from 'components/Button';
import Webcam from 'react-webcam';
import { convertBase64ToFile } from 'shared/helpers/HandleFiles';
import { steps } from 'utils/registrationStepsConstants';
import styles from './Camera.module.scss';

const Camera = ({ handleCameraImage }) => {
  const webcamRef = useRef(null);
  const [captureStatus, setCaptureStatus] = useState(false);

  const capture = useCallback(async () => {
    setCaptureStatus(true);
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const convertedFile = convertBase64ToFile(
        imageSrc,
        'photoIdentificationDocType'
      );
      setTimeout(() => {
        handleCameraImage({ file: convertedFile });
      }, 500);
    }

    setTimeout(() => {
      setCaptureStatus(false);
    }, 200);
  }, [webcamRef]);

  return (
    <div className="text-center">
      <h1 className="heading">Personal Documentation</h1>
      <div className="text-subdued">
        <p>
          Driver’s license or passport are acceptable forms of personal
          documentation. All bidders will be required to submit one form of
          identification before they will be issued bidder credentials.
        </p>
      </div>
      <div className={styles.frame}>
        <div
          className={`${styles.photoshootEffect} ${
            captureStatus ? styles.animated : ''
          }`}
        ></div>
        <Webcam
          height={670}
          width={552}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{
            width: { ideal: 552 },
            height: { ideal: 670 },
            facingMode: 'environment' || 'user',
          }}
        />
        <div className={styles.frameHint}>
          <p>Position your ID in the middle of the frame.</p>
        </div>
      </div>
      <footer
        className={`d-flex align-items-center justify-content-center position-relative ${styles.cameraFooter}`}
      >
        <Button
          type="app-primary-button btn-block take-photo-btn"
          handleClick={capture}
        >
          Take Photo
        </Button>
      </footer>
    </div>
  );
};

export default Camera;
