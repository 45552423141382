import React from 'react';
import _ from 'lodash';
import { useAuthenticatedQuery } from 'services/graphql/hooks';
import { GET_FOLLOWED_VEHICLES } from 'services/graphql/queries/vehicles-catalogue';
import { useCheckNotAuthenticatedUsers } from 'shared/helpers/Auth';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import ProfileHeader from 'features/account/profileHeader';
import List from 'features/account/list';
import styles from './following.module.scss';

const Following = () => {
  const isNotAuthenticated = useCheckNotAuthenticatedUsers();
  const { loading, error, data } = useAuthenticatedQuery(
    GET_FOLLOWED_VEHICLES,
    {
      skip: isNotAuthenticated,
    }
  );

  const vehiclesDuplicates = data?.followedVehicles?.vehicles;

  const sortByLatestAuctionDate = (a, b) => {
    let comparison = 0;
    if (new Date(a.auctionStartDate) < new Date(b.auctionStartDate)) {
      comparison = 1;
    } else if (new Date(a.auctionStartDate) > new Date(b.auctionStartDate)) {
      comparison = -1;
    }
    return comparison;
  };

  const vehicles =
    vehiclesDuplicates?.length > 0
      ? _.uniqBy(
          [...vehiclesDuplicates].sort(sortByLatestAuctionDate),
          'salesForceId'
        )
      : [];

  return (
    <Layout>
      <SEO title="Following" />
      {loading && <Spinner />}
      {error && <Alert color="danger" msg={error.message} />}
      <div className={styles.followingBody}>
        <div>
          <div className={styles.followingSection}>
            <ProfileHeader />
          </div>
          <div className={styles.heading}>
            <h1>My Favorites</h1>
            {vehicles?.length > 0 && (
              <>
                <h4>{vehicles?.length ?? ''} Vehicles</h4>
                <List vehicles={vehicles} />
              </>
            )}
            {vehicles?.length === 0 && !loading && (
              <em className="noResultsMsg d-flex align-items-center justify-content-center h-100">
                "No followed vehicles found"
              </em>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Following;
