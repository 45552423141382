import React, { useContext, useEffect, useState } from 'react';
import StripeTerminalContext from 'shared/context/StripeTerminalContext';
import Footer from 'privatePages/OnlineRegistration/Footer';
import styles from './Payment.module.scss';
import Item from './Item';
import { computePrice } from 'privatePages/OnlineRegistration/checkout';
import { amountFormatted } from 'shared/helpers/Currencies';
import {
  collectTerminalPaymentMethod,
  processTerminalPayment,
  createIntent,
  CHECKOUT_STEPS,
} from './stripeTerminal';
import { useAuthenticatedMutation } from 'services/graphql/hooks';
import {
  PAYMENT_CHECKOUT,
  CAPTURE_INTENT,
} from 'services/graphql/queries/Checkout';
import ModalWithButtons from 'components/Modal/ModalWithButtons';
import { navigate } from 'gatsby';
import { KIOSK_PATH } from 'utils/pathsConstants';
import Button from 'components/Button';

const KioskPaymentStep = ({
  isKiosk,
  isValid,
  values,
  auctions,
  handleSubmit,
  setTransparentLoader,
  setKioskModalMessage,
  cost,
  setCost,
  dispatchChevronStep,
  dispatchPrevSteps,
  setStep,
  handleStartOverButton,
}) => {
  // const [cost, setCost] = useState(0);
  const [costBreakdown, setCostBreakdown] = useState([]);
  const [errorModal, setErrorModal] = useState(null);
  const [
    retryCollectingPaymentMethod,
    setRetryCollectingPaymentMethod,
  ] = useState(false);
  const [checkoutStatus, setCheckoutStatus] = useState({
    nextStep: null,
    intent: {},
    clientSecret: '',
  });
  const { stripeTerminal } = useContext(StripeTerminalContext);

  const [
    CreateStripeIntent,
    { error: checkoutError, loading: checkoutLoading },
  ] = useAuthenticatedMutation(PAYMENT_CHECKOUT);

  const [
    CaptureStripeIntent,
    { error: captureIntentError, loading: captureIntentLoading },
  ] = useAuthenticatedMutation(CAPTURE_INTENT);

  useEffect(() => {
    const { price, pricesBreakdown } = computePrice(values, auctions);
    setCost(price);
    setCostBreakdown(pricesBreakdown);
  }, [values.auction]);

  useEffect(() => {
    setTransparentLoader(checkoutLoading || captureIntentLoading);
  }, [checkoutLoading, captureIntentLoading]);

  useEffect(() => {
    if (checkoutError) setErrorModal('Something went wrong.');
  }, [checkoutError]);

  useEffect(() => {
    if (captureIntentError) setErrorModal('Transaction failed.');
  }, [captureIntentError]);

  const handlePaymentButton = () => {
    if (cost) {
      if (localStorage.getItem('connectedReaderSN'))
        setCheckoutStatus({
          clientSecret: '',
          nextStep: CHECKOUT_STEPS.CREATE_INTENT,
          intent: {},
        });
      else
        setKioskModalMessage({
          title: 'Payment Failed',
          text: "Credit card reader isn't connected",
          disableToggling: false,
        });
    }
  };

  useEffect(() => {
    if (checkoutStatus?.nextStep) {
      checkout();
    }
  }, [checkoutStatus]);

  async function transitionToNextStep() {
    await handleSubmit(values);
  }

  function transitionToPrevStep() {
    dispatchChevronStep('back');
    const lastStep = dispatchPrevSteps({ type: 'pop' });
    setStep(lastStep);
  }

  async function checkout() {
    switch (checkoutStatus.nextStep) {
      case CHECKOUT_STEPS.CREATE_INTENT:
        await createIntent(cost, values, CreateStripeIntent, setCheckoutStatus);
        break;
      case CHECKOUT_STEPS.COLLECT_PAYMENT_METHOD:
        await collectTerminalPaymentMethod(
          stripeTerminal,
          checkoutStatus.clientSecret,
          setKioskModalMessage,
          setCheckoutStatus,
          setErrorModal
        );
        break;
      case CHECKOUT_STEPS.PROCESS_PAYMENT:
        await processTerminalPayment(
          stripeTerminal,
          checkoutStatus.intent,
          setCheckoutStatus,
          checkoutStatus,
          setErrorModal,
          setRetryCollectingPaymentMethod
        );
        break;
      case CHECKOUT_STEPS.CAPTURE_PAYMENT_IN_BACKEND:
        await CaptureStripeIntent({
          variables: {
            paymentIntentId: checkoutStatus.intent.id,
          },
        });
        setCheckoutStatus({
          clientSecret: '',
          nextStep: CHECKOUT_STEPS.DONE,
          intent: {},
        });
        break;
      case CHECKOUT_STEPS.DONE:
        transitionToNextStep();
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div className="user-input-form">
        <h1 className="heading">Make Payment</h1>
        <p className={`steps-hint ${styles.hintMargin}`}>
          The bidder registration fee includes a catalogue, admission for two to
          the viewing and auction with two reserved seats, subject to
          availability.
        </p>
        <p className={`steps-hint ${styles.hintMargin}`}>
          Please insert your credit card below to complete this payment.
        </p>
        <div className={styles.pricesList}>
          {costBreakdown.map((item, index) => (
            <Item item={item} key={'paymentStep' + index} />
          ))}
          <div className={`row no-gutters ${styles.rowMargin}`}>
            <div className="col-9">
              <div className={styles.bold}>order total</div>
            </div>
            <div className="col">
              <div className={styles.bold}>{amountFormatted(cost)}</div>
            </div>
          </div>
        </div>
        <div className={styles.paymentButton}>
          <Button
            type="app-primary-button"
            handleClick={
              !checkoutStatus?.nextStep ? handlePaymentButton : () => {}
            }
            disabled={!!checkoutStatus?.nextStep}
          >
            Pay Now
          </Button>
        </div>
      </div>
      <Footer
        isValid={isValid}
        isKiosk={isKiosk}
        submitButtonText="Submit"
        hideSubmitButton={true}
        handleStartOverButton={handleStartOverButton}
        handleBack={transitionToPrevStep}
        disableBackButton={!!checkoutStatus?.nextStep}
        disableStartOverButton={!!checkoutStatus?.nextStep}
      />
      {errorModal && (
        <ModalWithButtons
          title={'Paymnet Failed'}
          bodyText={errorModal}
          isOpen={errorModal ? true : false}
          buttons={[
            {
              text: 'Retry',
              handleSubmit: () => {
                if (retryCollectingPaymentMethod)
                  setCheckoutStatus({
                    ...checkoutStatus,
                    nextStep: CHECKOUT_STEPS.COLLECT_PAYMENT_METHOD,
                  });
                else checkout();
                setErrorModal(null);
              },
            },
            { text: 'Exit', handleSubmit: () => navigate(`/${KIOSK_PATH}`) },
          ]}
        />
      )}
    </>
  );
};

export default KioskPaymentStep;
