import React from 'react';
import Button from 'components/Button';
import SaveExit from 'components/SaveExit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltLeft,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import { KIOSK_PATH } from 'utils/pathsConstants';
import { SLUGS } from 'utils/webPageTypesConstants';

const Footer = ({
  handleBack,
  handleNext,
  isValid,
  submitButtonText,
  isKiosk,
  hideSubmitButton = false,
  handleStartOverButton,
  disableBackButton = false,
  disableStartOverButton = false,
  nextRef,
}) => {
  let title =
    'ARE YOU SURE YOU WANT TO LEAVE THE BIDDER REGISTRATION APPLICATION?';
  let content =
    'By leaving this page none of your bidder registration details will be lost.';
  const slugs = useWebPagesSlugs();
  return (
    <div className="form-footer">
      <div className="flex-wrapper">
        {handleBack && (
          <Button
            type="app-transparent-blue-button"
            handleClick={!disableBackButton ? handleBack : () => {}}
            disabled={disableBackButton}
          >
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
            Back
          </Button>
        )}
        {isKiosk && handleStartOverButton && (
          <Button
            type="app-transparent-blue-button"
            handleClick={
              !disableStartOverButton ? handleStartOverButton : () => {}
            }
            disabled={disableStartOverButton}
          >
            <FontAwesomeIcon icon={faUndoAlt} />
            Start Over
          </Button>
        )}
      </div>
      <div className="flex-wrapper" ref={nextRef}>
        {!isKiosk && (
          <SaveExit
            title={title}
            content={content}
            link={`/${slugs[SLUGS.registerToBid]}`}
            buttonName="Exit"
          />
        )}

        {!hideSubmitButton && (
          <Button
            type={
              isValid ? 'app-primary-button' : ' app-primary-button inactive'
            }
            handleClick={handleNext}
          >
            {submitButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Footer;
