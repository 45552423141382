import React from 'react';
import { Field } from 'formik';

export default ({ name, handleSelect, title, smallSize }) => (
  <Field name={name}>
    {({ field, form }) => (
      <label
        className={`checkbox-container ${
          smallSize ? 'checkbox-container-smallSize' : ''
        }`}
      >
        <input
          id={name}
          name={name}
          type="checkbox"
          checked={form.values[name]}
          onClick={() => handleSelect(form.values[name])}
          {...field}
        />
        <span
          className={`checkMark ${smallSize ? 'checkMark-smallSize' : ''}`}
        ></span>
        {title}
      </label>
    )}
  </Field>
);
