import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import PaymentIcons from './PaymentIcons';
import { PLACEHOLDER, RED } from 'utils/colorsConstants';
import TextInput from 'components/form-elements/TextInput';
import styles from './Stripe.module.scss';

const useOptions = () => {
  const options = useMemo(() => ({
    style: {
      base: {
        fontSize: '1.1rem',
        '::placeholder': {
          color: PLACEHOLDER,
        },
      },

      invalid: {
        color: RED,
      },
    },
  }));

  return options;
};

const StripeInfo = ({ setStripeValidations, handleDoneClick, stripeRef }) => {
  const options = useOptions();

  const [elementsData, setElementsData] = useState({
    cardNumber: {
      error: false,
      complete: false,
    },
    cardExpiry: {
      error: false,
      complete: false,
    },
    cardCvc: {
      error: false,
      complete: false,
    },
  });

  const handleChange = async ({ error, complete, empty, elementType }) => {
    setElementsData({
      ...elementsData,
      [elementType]: {
        error: error?.message || '',
        complete,
        empty,
      },
    });
  };

  useEffect(() => {
    setStripeValidations(_.some(elementsData, { complete: false }));
  }, [elementsData]);

  return (
    <div className={`${styles.sectionWrapper} my-4`}>
      <div className="d-flex align-items-center mb-4">
        <h2 className={`${styles.title} mb-0 mr-5`}>Your Payment Method</h2>
        <PaymentIcons />
      </div>

      <div className={`${styles.field}`}>
        <TextInput
          name="name"
          placeholder="Name on Card&#42;"
          onKeyDown={handleDoneClick}
          textRef={stripeRef}
        />
      </div>

      <div className={styles.stripeFieldGroup}>
        <div>
          <CardNumberElement
            options={{ ...options, placeholder: `Card Number*` }}
            className={styles.stripeField}
            onChange={handleChange}
          />
          <span className={styles.error}>{elementsData.cardNumber.error}</span>
        </div>

        <div className={styles.stripeSubFieldGroup}>
          <div>
            <CardExpiryElement
              options={{ ...options, placeholder: `Exp MM/YY*` }}
              className={styles.stripeField}
              onChange={handleChange}
            />
            <span className={styles.error}>
              {elementsData.cardExpiry.error}
            </span>
          </div>
          <div>
            <CardCvcElement
              options={{ ...options, placeholder: `CVC*` }}
              className={styles.stripeField}
              onChange={handleChange}
            />
            <span className={styles.error}>{elementsData.cardCvc.error}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeInfo;
