import { useStaticQuery, graphql } from 'gatsby';

const useStaticQueryGetPrivacyPolicyDate = () => {
  const query = useStaticQuery(
    graphql`
      query privacyPolicy {
        privacyPolicy: contentfulWebRichTextSection(
          title: { eq: "Privacy Policy" }
        ) {
          updatedAt
        }
      }
    `
  );
  return {
    privacyPolicy: query.privacyPolicy,
  };
};

export default useStaticQueryGetPrivacyPolicyDate;
