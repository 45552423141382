import React from 'react';
import {
  getAuction,
  getPrice,
  getTag,
  getAlgoliaItemTitle,
} from 'shared/helpers/vehicles';
import { combineCloudinaryWithImages } from 'utils/image';
import VehicleCard from 'components/VehicleCard';
import styles from './list.module.scss';

const List = ({ vehicles = [], recentlyViewed = false, list = false }) => {
  return (
    <div className={styles.vehiclesListContainer}>
      <div
        className={`${recentlyViewed && styles.recentlyViewed} ${
          styles.vehiclesList
        } ${list && styles.recentlyViewedList}`}
      >
        {vehicles.map((vehicle, index) => (
          <VehicleCard
            key={`vehicleCard_${index}`}
            fluidImages={combineCloudinaryWithImages(vehicle, 500, 3)}
            tag={getTag(vehicle)}
            makeModel={getAlgoliaItemTitle(vehicle)}
            auction={getAuction(vehicle, true)}
            price={getPrice(vehicle, vehicle?.currency)}
            lotNumber={!recentlyViewed && vehicle.lotNumber}
            slug={vehicle.slug}
            showDotsOnHover={true}
            salesForceId={vehicle?.salesForceId}
            onlineBiddingAvailable={vehicle.onlineBiddingAvailable}
            recentlyViewed={recentlyViewed}
            className={recentlyViewed ? 'appVehicleCardRecentlyViewed' : ''}
            // cardType={recentlyViewed && list ? 'list' : 'grid'}
            list={list}
          />
        ))}
      </div>
    </div>
  );
};

export default List;
