import React, { useEffect, useRef, useState } from 'react';
import FileInput from 'components/form-elements/FileInput';
import Select from 'components/form-elements/Select';
import Button from 'components/Button';
import FileUpload from 'features/onlineRegistration/FileUpload';
import { OPTIONS, P1, P2, KIOSK_OPTIONS, KIOSK_P1 } from './data';
import './step.scss';
import Footer from '../Footer';
import { steps } from 'utils/registrationStepsConstants';
import { BIDDING_METHODS } from 'src/utils/auctionTypes';
import Camera from 'adminPages/Kiosk/Steps/Camera';
import CloseIcon from 'svg/CloseIcon';
import { convertFileToBase64 } from 'shared/helpers/HandleFiles';

export default ({
  values,
  setFieldValue,
  // isValid,
  // setStep,
  // isFinancialDateOutdated,
  // handleSubmit,
  // dispatchChevronStep,
  setFieldTouched,
  // dispatchPrevSteps,
  isKiosk,
  // handleStartOverButton,
}) => {
  const photoIdentificationRef = useRef(null);
  const additionalIdentificationRef = useRef(null);
  const [identificationFile, updateIdentificationFile] = useState(
    values.photoIdentificationFile
  );
  const [additionalFile, updateAdditionalFile] = useState(
    values.additionalIdentificationFile
  );

  const [cameraInfo, setCameraInfo] = useState({
    isOpen: false,
    ref: 'photoIdentificationCam',
  });

  const [photoIdentificationCamSrc, setPhotoIdentificationCam] = useState('');

  useEffect(() => {
    values.photoIdentificationFile &&
      convertFileToBase64(values.photoIdentificationFile).then(res => {
        setPhotoIdentificationCam(res);
      });
  }, [values.photoIdentificationFile]);

  const handleCameraImage = ({ file }) => {
    if (cameraInfo.ref === 'photoIdentificationCam') {
      setFieldValue('photoIdentificationFile', file);
      setFieldTouched('photoIdentificationFile', true);
      updateIdentificationFile(file);
    } else {
      setFieldValue('additionalIdentificationFile', file);
      setFieldTouched('additionalIdentificationFile', true);
      updateAdditionalFile(file);
    }
    setCameraInfo({
      isOpen: false,
      ref: cameraInfo.ref,
    });
  };

  const removeImage = type => {
    if (type === 'photoIdentificationCam') {
      setPhotoIdentificationCam('');
      updateIdentificationFile('');
      setFieldValue('photoIdentificationFile', '');
    } else {
      updateAdditionalFile('');
      setFieldValue('additionalIdentificationFile', '');
    }
  };

  // async function submit() {
  //   if (!isValid) return;
  //   await transitionToNextStep();
  // }

  // async function transitionToNextStep() {
  //   dispatchPrevSteps({
  //     type: 'push',
  //     payload: steps.UPLOADING_PERSONAL_DOCUMENTS,
  //   });

  //   dispatchChevronStep('next');

  //   if (!isKiosk && isFinancialDateOutdated) {
  //     setStep(steps.FINANCIAL_DATA);
  //   } else if (values.biddingMethod === BIDDING_METHODS.TELEPHONE_BIDDING) {
  //     setStep(steps.TELEPHONE_BIDDING_DETAILS);
  //   } else if (values.biddingMethod === BIDDING_METHODS.IN_PERSON_BIDDING) {
  //     setStep(isKiosk ? steps.KIOSK_PAYMENT : steps.PAYMENT);
  //   } else {
  //     await handleSubmit(values);
  //   }
  // }

  // function transitionToPrevStep() {
  //   dispatchChevronStep('back');
  //   const prevStep = dispatchPrevSteps({ type: 'pop' });
  //   setStep(prevStep);
  // }
  return (
    <>
      {!cameraInfo.isOpen ? (
        <div>
          <div className="user-input-form step-4">
            <h1 className="heading">Personal Documentation</h1>
            <div className="text-subdued">
              <p>{isKiosk ? KIOSK_P1 : P1}</p>
              <p>{P2}</p>
            </div>
            <div className="section">
              <h3 className="sub-heading">Photo Identification</h3>
              <div className="field-group align-items-center">
                <div
                  className={`${isKiosk ? 'kiosk-field-document' : 'field'}`}
                >
                  <Select
                    name="photoIdentificationDocType"
                    data={isKiosk ? KIOSK_OPTIONS : OPTIONS}
                    placeholder="Document Type&#42;"
                  />
                </div>
                {!isKiosk ? (
                  <div className="field">
                    <FileUpload
                      browseFile={() => photoIdentificationRef.current.click()}
                      setValue={value => {
                        setFieldValue('photoIdentificationFile', value);
                        setFieldTouched('photoIdentificationFile', true);
                        updateIdentificationFile(value);
                      }}
                    />
                    <FileInput
                      name="photoIdentificationFile"
                      forwardedRef={photoIdentificationRef}
                      acceptFiles="image/png,image/jpeg,application/pdf"
                      errorMsg="File type must be .PNG, .JPG or .PDF"
                      clickHandler={value => {
                        setFieldTouched('photoIdentificationFile', true);
                      }}
                      updateFile={updateIdentificationFile}
                      file={identificationFile}
                    />
                  </div>
                ) : (
                  <Button
                    type="app-primary-button btn-block take-photo-btn"
                    handleClick={() =>
                      setCameraInfo({
                        isOpen: true,
                        ref: 'photoIdentificationCam',
                      })
                    }
                  >
                    Take Photo
                  </Button>
                )}
              </div>
              {isKiosk && photoIdentificationCamSrc && (
                <div
                  className="camera-image-preview"
                  style={{
                    backgroundImage: `url(${photoIdentificationCamSrc})`,
                  }}
                >
                  <CloseIcon
                    onClick={() => {
                      removeImage('photoIdentificationCam');
                    }}
                    color="black"
                  />
                </div>
              )}
            </div>
            {!isKiosk ? (
              <div
                className={`section ${
                  !values.photoIdentificationFile ? 'hidden' : ''
                }`}
              >
                <h3 className="sub-heading">Additional Identification</h3>
                <div className="field-group align-items-center">
                  <div
                    className={`${isKiosk ? 'kiosk-field-document' : 'field'}`}
                  >
                    <Select
                      name="additionalIdentificationDocType"
                      data={OPTIONS.filter(
                        data => data.value !== values.photoIdentificationDocType
                      )}
                      placeholder="Document Type"
                    />
                  </div>

                  <div className="field">
                    <FileUpload
                      browseFile={() =>
                        additionalIdentificationRef.current.click()
                      }
                      setValue={value => {
                        setFieldValue('additionalIdentificationFile', value);
                        setFieldTouched('additionalIdentificationFile', true);
                        updateAdditionalFile(value);
                      }}
                    />
                    <FileInput
                      name="additionalIdentificationFile"
                      forwardedRef={additionalIdentificationRef}
                      acceptFiles="image/png,image/jpeg,application/pdf"
                      errorMsg="File type must be .PNG, .JPG or .PDF"
                      clickHandler={() =>
                        setFieldTouched('additionalIdentificationFile', true)
                      }
                      updateFile={updateAdditionalFile}
                      file={additionalFile}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          {/* <Footer
            handleNext={() => submit()}
            handleBack={() => transitionToPrevStep()}
            isValid={isValid}
            isKiosk={isKiosk}
            submitButtonText={
              ((!isFinancialDateOutdated && !isKiosk) || isKiosk) &&
              values.biddingMethod === BIDDING_METHODS.ONLINE_BIDDING
                ? 'Submit'
                : 'Next'
            }
            handleStartOverButton={handleStartOverButton}
          /> */}
        </div>
      ) : (
        <Camera handleCameraImage={handleCameraImage} />
      )}
    </>
  );
};
