import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Button from 'components/Button';
import TextInput from 'components/form-elements/TextInput';
import { SCHEMA, INITIAL_VALUES } from 'components/utils/changePassword';
import ConfirmAlert from 'components/ConfirmAlert';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import { UPDATE_MESSAGES } from 'utils/UpdateMessages';
import { useAuthenticatedMutation } from 'services/graphql/hooks';
import { CHANGE_PASSWORD } from 'services/graphql/queries/profile';
import styles from '../Change.module.scss';
import { useAuth0 } from '@auth0/auth0-react';

const ChangePassword = ({ profile }) => {
  const [changePassword, { error, loading }] = useAuthenticatedMutation(
    CHANGE_PASSWORD
  );

  const [success, setSuccess] = useState(false);
  const { loginWithRedirect } = useAuth0();
  const [redirectionLoader, setRedirectionLoader] = useState(false);

  const showSuccessMessage = () => {
    setSuccess(true);
    setTimeout(() => setSuccess(false), 7000);
  };

  const handleSubmit = (values, { resetForm }) => {
    const { currentPassword, newPassword } = values;

    return changePassword({
      variables: {
        oldPassword: currentPassword,
        newPassword: newPassword,
        username: profile.email,
      },
    }).then(() => {
      showSuccessMessage();
      resetForm(INITIAL_VALUES);
      setRedirectionLoader(true);
    });
  };
  useEffect(() => {
    if (redirectionLoader) {
      setTimeout(() => {
        setRedirectionLoader(false);
        localStorage.setItem(
          'currentRoute',
          `${window.location.pathname}${window.location.search}`
        );
        loginWithRedirect();
      }, 2000);
    }
  }, [redirectionLoader]);

  return (
    <>
      {error && <Alert color="danger" msg={error.message} />}
      {(loading || redirectionLoader) && <Spinner />}
      <Formik
        validateOnMount
        initialValues={INITIAL_VALUES}
        validationSchema={SCHEMA}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting, touched }) => {
          return (
            <Form className="d-flex flex-column h-100">
              <div>
                <h2 className={styles.subHeading}>Change Password</h2>
                <div className={`${styles.fieldSpace}`}>
                  <TextInput
                    type="password"
                    name="currentPassword"
                    placeholder="Current Password&#42;"
                  />
                </div>
                <div className={`${styles.fieldSpace}`}>
                  <TextInput
                    type="password"
                    name="newPassword"
                    placeholder="New Password&#42;"
                  />
                </div>
                <div className={`${styles.fieldSpace}`}>
                  <TextInput
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm New Password&#42;"
                  />
                </div>
              </div>
              <Button
                disabled={
                  !isValid || isSubmitting || Object.keys(touched).length === 0
                }
                type={`${styles.updateBtn} app-primary-button`}
                btnType="submit"
              >
                Update
              </Button>
            </Form>
          );
        }}
      </Formik>
      <ConfirmAlert message={UPDATE_MESSAGES['password']} show={success} />
    </>
  );
};

export default ChangePassword;
