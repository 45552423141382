import React, { Fragment } from 'react';
import PaymentInfo from './PaymentDetails';
import BillingInformation from './BillingInformation';

const UserInfo = ({
  setFieldValue,
  country,
  state,
  amount,
  cart,
  RemoveItem,
  isUk = false,
}) => {
  return (
    <Fragment>
      <PaymentInfo
        amount={amount}
        items={cart}
        RemoveItem={RemoveItem}
        isUk={isUk}
      />
      <BillingInformation
        setFieldValue={setFieldValue}
        state={state}
        country={country}
        isUk={isUk}
      />
    </Fragment>
  );
};

export default UserInfo;
