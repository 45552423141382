import { gql } from '@apollo/client';

export const GET_KIOSK_BIDDER_DATA = gql`
  query GetKioskBidderData($email: String) {
    bidderData(email: $email) {
      id
      firstName
      lastName
      birthdate
      email
      mainPhone
      address1
      address2
      city
      state
      zipcode
      country
      financialDocumentSubmissionDate
      privacyPolicyAcceptanceDate
      buyerStatus
      company
      personalDocumentSubmissionDate
    }
  }
`;

export const GET_USER_CAMPAIGNS_BY_ID = gql`
  query userRegisteredCampaignsById($sfId: String) {
    userRegisteredCampaignsById(sfId: $sfId) {
      registeredCampaigns {
        campaignId
      }
    }
  }
`;

export const CREATE_USER_IN_SALESFORCE = gql`
  mutation CreateUserInSalesforce($bidder: BidderInput!) {
    createUserInSalesforce(bidder: $bidder) {
      id
    }
  }
`;

export const VERIFY_BIDDER = gql`
  mutation verifyBidderData(
    $verifiableData: BidderVerifiableData!
    $userSalesforceId: String!
  ) {
    verifyBidderData(
      verifiableData: $verifiableData
      userSalesforceId: $userSalesforceId
    ) {
      verificationStatus
    }
  }
`;
