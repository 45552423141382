import * as Yup from 'yup';

const INITIAL_VALUES = {
  referenceId: '',
  amount: '',
  firstName: '',
  lastName: '',
  receiptEmail: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  postalCode: '',
  country: 'United States',
  name: '',
};
const UK_INITIAL_VALUES = {
  referenceId: '',
  amount: '',
  firstName: '',
  lastName: '',
  receiptEmail: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  postalCode: '',
  country: 'United Kingdom',
  name: '',
};
const VALIDATION_SCHEMA = Yup.object().shape({
  referenceId: Yup.string().required('Reference ID is required.'),
  amount: Yup.number()
    .positive()
    .max(10000)
    .required('Amount is required.')
    .test('decimal-limit', 'Amount accept up to two decimal numbers', value => {
      const pattern = /^\d+(?:\.\d{1,2})?$/;
      if (value) {
        return pattern.test(value);
      }
    }),
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  line1: Yup.string().required('Address 1 is required.'),
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.'),
  country: Yup.string().required('Country is required.'),
  postalCode: Yup.string().required('Zipcode is required.'),
  receiptEmail: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is required.'),
  name: Yup.string().required('This field is required for payment.'),
});

const LIST_VALIDATION_SCHEMA = Yup.object().shape({
  referenceId: Yup.string().required('Reference ID is required.'),
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  line1: Yup.string().required('Address 1 is required.'),
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.'),
  country: Yup.string().required('Country is required.'),
  postalCode: Yup.string().required('Zipcode is required.'),
  receiptEmail: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is required.'),
});

export { INITIAL_VALUES, VALIDATION_SCHEMA, UK_INITIAL_VALUES };
