import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './checkout-form';

export const CheckoutUk = ({ location }) => {
  const stripeUk = loadStripe(process.env.GATSBY_STRIPE_UK_KEY);

  return (
    <Elements stripe={stripeUk}>
      <CheckoutForm location={location} />
    </Elements>
  );
};
export default CheckoutUk;
