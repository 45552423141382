import { gql } from '@apollo/client';

export const PAYMENT_CHECKOUT = gql`
  mutation CreateIntent($checkoutData: CreateIntentInput!) {
    CreateIntent(intentInput: $checkoutData) {
      clientSecret
    }
  }
`;

export const CAPTURE_INTENT = gql`
  mutation CaptureIntent($paymentIntentId: String!) {
    CaptureIntent(paymentIntentId: $paymentIntentId)
  }
`;
