import axios from 'axios';
import { navigate } from 'gatsby';
import moment from 'moment';
import { pick } from 'lodash';
import { BIDDING_METHODS } from 'src/utils/auctionTypes';
import { steps } from 'utils/registrationStepsConstants';

export const uploadDocuments = async (
  values,
  getUploadURLs,
  setUploadingAssets,
  isKiosk,
  bidderSalesforceId
) => {
  const {
    photoIdentification,
    additionalIdentification,
    bankLetterOfGuarantee,
  } = getDocuments(values);

  const userFiles = [
    {
      file: photoIdentification,
      type: values.photoIdentificationDocType,
    },
    {
      file: additionalIdentification,
      type: values.additionalIdentificationDocType,
    },
    {
      file: bankLetterOfGuarantee,
      type: 'bank_letter',
    },
  ].filter(({ file }) => file);

  const userFilesExtensions = userFiles.map(f => f.file.name.split('.').pop());
  try {
    const { data } = await getUploadURLs({
      variables: {
        signedAssetInput: {
          publicAccess: false,
          extensions: userFilesExtensions,
        },
        ...(isKiosk && { userSalesforceId: bidderSalesforceId }),
      },
    });

    const urls = data?.CreateAdminUploadURL || data?.CreateUploadURL;
    if (urls) {
      const files = urls.map((asset, i) => ({
        name: `${userFiles[i].type}_${asset.fileId}`,
        key: asset.fileId,
      }));
      const attachments = urls.map(asset => asset.fileId);

      setUploadingAssets(true);
      await Promise.all(
        urls.map((asset, i) => {
          return axios.put(asset.uploadURL, userFiles[i].file, {
            headers: {
              'Content-Type': userFiles[i].file.type,
            },
          });
        })
      ).finally(() => {
        setUploadingAssets(false);
      });

      return { files, attachments };
    }
  } catch (error) {
    throw new Error('Failed to upload attachments');
  }
};

export const registerToBid = async (
  values,
  auctions,
  register,
  userInfoUpdated,
  isFinancialDateOutdated,
  registrationTotal,
  isPersonalDateOutdated,
  registerToBidSlug,
  isKiosk,
  bidderSalesforceId,
  setStep,
  uploadMeta
) => {
  const registrationData = {
    ...getRegistrationData(
      values,
      auctions,
      registrationTotal,
      userInfoUpdated,
      isFinancialDateOutdated,
      isPersonalDateOutdated
    ),
    ...uploadMeta,
  };

  await register({
    variables: {
      data: registrationData,
      ...(isKiosk && { userSalesforceId: bidderSalesforceId }),
    },
  });

  if (window.dataLayer)
    window.dataLayer.push({
      event: 'register-to-bid-submit-form',
    });

  if (isKiosk) {
    setStep(steps.KIOSK_CONFIRMATION);
  } else {
    navigate(`/${registerToBidSlug}`, {
      state: {
        modal: {
          showModal: true,
          title: 'Success! Registration Submitted',
          bodyText: getSuccessModalBody(
            isFinancialDateOutdated,
            values.biddingMethod === BIDDING_METHODS.IN_PERSON_BIDDING
          ),
          className: 'failure-exit-modal',
        },
      },
    });
  }
};

const getDocuments = values => ({
  photoIdentification: values.photoIdentificationFile,
  additionalIdentification: values.additionalIdentificationFile,
  bankLetterOfGuarantee: values.bankLetterFile,
});

const getRegistrationData = (
  values,
  auctions,
  registrationTotal,
  userInfoUpdated,
  isFinancialDateOutdated,
  isPersonalDateOutdated
) => {
  const price = registrationTotal ?? 0;
  const telephoneData = {};

  if (values.biddingMethod === BIDDING_METHODS.TELEPHONE_BIDDING)
    Object.assign(telephoneData, {
      primaryPhone: values.primaryPhone,
      secondaryPhone: values.secondaryPhone || '',
      lots: values.telephoneBiddingLots || [],
    });

  return {
    ...pick(values, [
      'firstName',
      'lastName',
      'email',
      'city',
      'state',
      'country',
      'bankName',
      'bankStreetAddress',
      'biddingMethod',
      'company',
    ]),
    birthDate: moment(values.dateOfBirth).format('YYYY-MM-DD'),
    address: values.aboutYouAddress1,
    address2: values.aboutYouAddress2,
    postalCode: values.zipcode,
    shippingAddress: values.shippingAddress,
    shippingAddress2: values.shippingAddress2,
    shippingAddressCity: values.shippingCity,
    shippingAddressState: values.shippingState,
    shippingAddressPostalCode: values.shippingZipcode,
    shippingAddressCountry: values.shippingCountry,
    mobilePhone: values.phone,
    branchTelephoneNumber: values.bankPhone,
    bankPostalCode: values.bankZipcode,
    branchOfficerAccountOfficer: values.bankBranchOffice,
    requestedBiddingLimit: values.biddingLimits,
    initials: values.initialsUS || values.initialsUK,
    browserDetails: navigator.userAgent,
    registrationTotal: price,
    status: values.buyerStatus,
    auctions: getAuctionsData(values, auctions),
    conditionOfSaleTimeStampUS: values.initialsUS
      ? values.conditionsOfSaleSubmitTimeStamp
      : '',
    conditionOfSaleTimeStampUK: values.initialsUK
      ? values.conditionsOfSaleSubmitTimeStamp
      : '',
    userInfoUpdated:
      userInfoUpdated || isFinancialDateOutdated || isPersonalDateOutdated,
    ...telephoneData,
    ...(isFinancialDateOutdated && {
      financialDocumentSubmissionDate: new Date(),
    }),
    ...(isPersonalDateOutdated && {
      personalDocumentSubmissionDate: new Date(),
    }),
  };
};

const getAuctionsData = (values, auctions) => {
  const selectedAuctions = [];
  if (values.auction.length > 0) {
    values.auction.forEach(auction => {
      const auctionWithFullData = auctions.find(x => x.objectID === auction);
      const selectedAuction = {
        name: auctionWithFullData.name,
        id: auctionWithFullData.objectID,
        auctionMobilityId: auctionWithFullData.auctionMobilityId,
        campaignId: auctionWithFullData.campaignId,
      };
      selectedAuctions.push(selectedAuction);
    });
  }
  return selectedAuctions;
};

const getSuccessModalBody = (
  isFinancialDateOutdated,
  inPersonBiddingMethod
) => {
  if (inPersonBiddingMethod)
    return '<p>Thank you for completing your bidding registration and payment - you will receive a confirmation email and receipt shortly. We will be in touch within two business days with any questions or with confirmation of your approved registration.</p>';
  else if (!isFinancialDateOutdated)
    return `<p>Thank you for completing your bidder registration – you will receive
  a confirmation email shortly. We will be in touch within two business
  days with any questions or with confirmation of your approved registration<p/>`;
  else
    return `<p>  Thank you for registering to bid with Gooding & Company.
  Your registration is complete and you will receive a confirmation
  email with additional details shortly.
  Should you have any additional questions,
  please reach out to the Gooding & Company Bidder Registration 
  staff at <a href="mailto:bids@goodingco.com">bids@goodingco.com</a>
  or by phone at <a href="tel:310.899.1960">310.899.1960</a>.</p>`;
};
