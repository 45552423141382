import React, { useState } from 'react';
import { navigate } from 'gatsby';
import SEO from 'components/seo';
import Layout from 'components/Layout';
import Loading from 'components/Spinner';
import Alert from 'components/Alert';
import EmailVerificationCard from 'components/EmailVerificationCard';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthenticatedMutation } from 'services/graphql/hooks';
import { redirectCallbackAfterLogin } from 'shared/helpers/Auth';
import { RESEND_VERIFICATION_EMAIL } from 'services/graphql/queries/ResendVerification';
import styles from './unverified.module.scss';
import * as amplitude from '@amplitude/analytics-browser';
const Unverified = () => {
  const { isLoading, isAuthenticated, user } = useAuth0();

  const [
    resendVerificationEmail,
    { loading, error },
  ] = useAuthenticatedMutation(RESEND_VERIFICATION_EMAIL);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const showStatusMessage = status => {
    switch (status) {
      case 'success':
        setShowSuccess(true);
        setIsDisabled(true);
        setTimeout(() => setShowSuccess(false), 5000);
        break;
      case 'error':
        setShowError(true);
        setIsDisabled(false);
        setTimeout(() => setShowError(false), 5000);
        break;
      default:
        return;
    }
  };

  if (isLoading) return <Loading />;
  else if (isAuthenticated) {
    if (!user.email_verified)
      return (
        <Layout transparentHeader={false} paddingTop={true}>
          {loading && <Loading />}
          <SEO title="Email Verification" />
          <div className={styles.emailVerificationWrapper}>
            <EmailVerificationCard
              email={user.email}
              isDisabled={isDisabled}
              handleResend={async () => {
                try {
                  await resendVerificationEmail();
                  showStatusMessage('success');
                } catch (error) {
                  showStatusMessage('error');
                }
              }}
            />
          </div>
          {showSuccess && (
            <Alert
              color="success"
              msg="A verification link has been sent to your email account successfully"
            />
          )}
          {showError && <Alert color="danger" msg={error.message} />}
        </Layout>
      );
    else {
      amplitude.track(
        `${process.env.NODE_ENV === 'development' ? 'dev-' : ''} Sign Up`
      );
      navigate('/');
    }
  } else {
    redirectCallbackAfterLogin();
  }

  return <Loading />;
};

export default Unverified;
