import { DEV } from 'shared/helpers/FeatureToggle/constants';

export const CHECKOUT_STEPS = {
  CREATE_INTENT: 'create_intent',
  COLLECT_PAYMENT_METHOD: 'collect_payment_method',
  PROCESS_PAYMENT: 'process_payment',
  CAPTURE_PAYMENT_IN_BACKEND: 'capture_payment_in_backend',
  DONE: 'done',
};

export const createIntent = async (
  cost,
  values,
  CreateStripeIntent,
  setCheckoutStatus
) => {
  if (!cost) return;
  const checkoutData = {
    amount: +(cost * 100).toFixed(),
    receiptEmail: values.email,
    shipping: {
      name: `${values.firstName} ${values.lastName}`,
      line1: values.aboutYouAddress1,
      line2: values.aboutYouAddress2,
      city: values.city,
      country: values.country,
      postalCode: values.zipcode,
      state: values.state,
    },
    terminalPayment: true,
  };
  const intent = await CreateStripeIntent({
    variables: { checkoutData },
  });
  if (intent?.data) {
    setCheckoutStatus({
      nextStep: CHECKOUT_STEPS.COLLECT_PAYMENT_METHOD,
      intent: {},
      clientSecret: intent.data.CreateIntent.clientSecret,
    });
  }
};

export const collectTerminalPaymentMethod = async (
  terminal,
  client_secret,
  setKioskModalMessage,
  setCheckoutStatus,
  setErrorModal
) => {
  setKioskModalMessage({
    title: 'Insert your Credit Card',
    text: 'Collecting payment Method...',
    disableToggling: true,
  });

  const collectPaymentData = { error: false, paymentIntent: {} };

  if (process.env.GATSBY_STAGE === DEV)
    await terminal.setSimulatorConfiguration({
      // testCardNumber: '4000000000000002',
      testCardNumber: '4242424242424242',
    });
  await terminal
    .collectPaymentMethod(client_secret)
    .then(async function (result) {
      setKioskModalMessage(null);
      if (result.error) {
        collectPaymentData.error = result.error;
        setErrorModal(collectPaymentData.error.message);
      } else {
        collectPaymentData.paymentIntent = result.paymentIntent;
        setCheckoutStatus({
          clientSecret: client_secret,
          nextStep: CHECKOUT_STEPS.PROCESS_PAYMENT,
          intent: collectPaymentData.paymentIntent,
        });
      }
    });

  return;
};

export const processTerminalPayment = async (
  terminal,
  paymentIntent,
  setCheckoutStatus,
  checkoutStatus,
  setErrorModal,
  setRetryCollectingPaymentMethod
) => {
  const paymentResult = { error: false, paymentIntent: {} };
  await terminal.processPayment(paymentIntent).then(async function (result) {
    if (result.error) {
      paymentResult.error = result.error;
      if (paymentResult.error.code === 'card_declined')
        setRetryCollectingPaymentMethod(true);
      return setErrorModal(paymentResult.error.message);
    } else if (result.paymentIntent) {
      paymentResult.paymentIntent = result.paymentIntent;
      setCheckoutStatus({
        ...checkoutStatus,
        nextStep: CHECKOUT_STEPS.CAPTURE_PAYMENT_IN_BACKEND,
        intent: paymentResult.paymentIntent,
      });
    }
  });
  return;
};
