import * as Yup from 'yup';
import { isValidDateOfBirth } from './dateOfBirthValidation';
import moment from 'moment';
import { steps } from 'utils/registrationStepsConstants';
import {
  defaultBuyerStatus,
  buyerStatusTypes,
} from 'privatePages/OnlineRegistration/register-to-bid.utils';
const FILE_SIZE = 20000 * 1024;
const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/pdf',
];

export function getRegisterToBidInitialValues(user, isKiosk = false) {
  const nonKioskStandardBidder = !(
    isKiosk && user.buyerStatus === buyerStatusTypes.STANDARD
  );
  const formFields = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    dateOfBirth:
      nonKioskStandardBidder && user?.birthdate
        ? moment.utc(new Date(user.birthdate)).format('MM/DD/YYYY')
        : '',
    company: (nonKioskStandardBidder && user?.company) || '',
    aboutYouAddress1: (nonKioskStandardBidder && user?.address1) || '',
    aboutYouAddress2: (nonKioskStandardBidder && user?.address2) || '',
    city: (nonKioskStandardBidder && user?.city) || '',
    state: (nonKioskStandardBidder && user?.state) || '',
    country: (nonKioskStandardBidder && user?.country) || 'United States',
    zipcode: (nonKioskStandardBidder && user?.zipcode) || '',
    phone: (nonKioskStandardBidder && user?.mainPhone) || '',
    auction: [],
    biddingMethod: '',
    initialsUS: '',
    initialsUK: '',
    photoIdentificationDocType: '',
    photoIdentificationFile: '',
    additionalIdentificationDocType: '',
    additionalIdentificationFile: '',
    bankLetterFile: '',
    bankName: '',
    bankStreetAddress: '',
    bankPhone: '',
    bankZipcode: '',
    bankBranchOffice: '',
    biddingLimits: '',
    acceptFinancialTerms: false,
    buyerStatus: user?.buyerStatus || defaultBuyerStatus,
    cardNumber: '',
    exp: '',
    cvc: '',
    billAddress: false,
    primaryPhone: '',
    secondaryPhone: '',
    lotNumber0: '',
    description0: '',
    maximumBid0: '',
    lotNumber1: '',
    description1: '',
    maximumBid1: '',

    // For Payment Form
    // firstName: user.firstName || '',
    // lastName: user.lastName || '',
    // company: '',
    bidderPrimaryAddress: '',
    bidderSecondaryAddress: '',
    // city: user.city1 || '',
    // state: '',
    // country: '',
    // zipcode: user.zipcode1 || '',
    // phone: user.mainPhone1 || '',

    firstName0: user?.firstName || '',
    lastName0: user?.lastName || '',
    aboutYouAddress01: (nonKioskStandardBidder && user?.address1) || '',
    aboutYouAddress02: (nonKioskStandardBidder && user?.address2) || '',
    city0: (nonKioskStandardBidder && user?.city) || '',
    state0: (nonKioskStandardBidder && user?.state) || '',
    country0: (nonKioskStandardBidder && user?.country) || 'United States',
    zipcode0: (nonKioskStandardBidder && user?.zipcode) || '',
    shipping: 'Fedex Domestic Express (2-3 day delivery)',
    shippingAddress: (nonKioskStandardBidder && user?.shippingAddress) || '',
    shippingAddress2: (nonKioskStandardBidder && user?.shippingAddress2) || '',
    shippingCity: (nonKioskStandardBidder && user?.shippingAddressCity) || '',
    shippingState: (nonKioskStandardBidder && user?.shippingAddressState) || '',
    shippingZipcode:
      (nonKioskStandardBidder && user?.shippingAddressPostalCode) || '',
    shippingCountry:
      (nonKioskStandardBidder && user?.shippingAddressCountry) ||
      'United States',
    name: '',
    personalDocumentSubmissionDate: user?.personalDocumentSubmissionDate,
    financialDocumentSubmissionDate: user?.financialDocumentSubmissionDate,
    kioskPaymentSucceeded: false,
  };
  return formFields;
}

const emailSchema = () => {
  let schemaAttributes = {
    email: Yup.string()
      .email('Please enter a valid email.')
      .required('Email is required.')
      .max(80),
  };
  return Yup.object().shape(schemaAttributes);
};

const userInfoSchema = () => {
  let schemaAttributes = {
    firstName: Yup.string()
      .label('First Name')
      .required('First Name is required.')
      .min(1, 'Too short')
      .max(20),
    lastName: Yup.string()
      .label('Last Name')
      .required('Last Name is required.')
      .min(1, 'Too short')
      .max(20),
    aboutYouAddress1: Yup.string()
      .label('Address1')
      .required('Address 1 is required.')
      .min(1, 'Too short')
      .max(100),
    aboutYouAddress2: Yup.string().label('Address2').min(0).max(100),
    city: Yup.string()
      .required('City is required.')
      .min(1, 'Too short')
      .max(40),
    state: Yup.string().required('State is required.'),
    country: Yup.string().required('Country is required.'),
    zipcode: Yup.string()
      .required('Zipcode is required.')
      .min(1, 'Too short')
      .max(10),
    phone: Yup.string().required('Phone is required').nullable(),
    email: Yup.string()
      .max(255)
      .email('Please enter a valid email.')
      .required('Email is required.')
      .max(255, 'Too long'),
    dateOfBirth: Yup.string()
      .required('Date of Birth is required.')
      .nullable()
      .test('valid', 'Please enter valid Date Of Birth', value =>
        isValidDateOfBirth(value)
      ),
    company: Yup.string().min(0).max(100),
    photoIdentificationDocType: Yup.string().required(
      'Document Type is required.'
    ),
    photoIdentificationFile: Yup.mixed()
      .required('A file is required.')
      .test(
        'fileSize',
        'File too large.',
        value => value && value.size <= FILE_SIZE
      )
      .test(
        'fileFormat',
        'Unsupported Format.',
        value => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    additionalIdentificationFile: Yup.mixed()
      .test('fileSize', 'File too large.', value =>
        value ? value.size <= FILE_SIZE : true
      )
      .test('fileFormat', 'Unsupported Format.', value =>
        value ? SUPPORTED_FORMATS.includes(value.type) : true
      ),
  };
  return Yup.object().shape(schemaAttributes);
};

const auctionsSelectionSchema = () => {
  let schemaAttributes = {
    auction: Yup.string().required('Please choose an Auction'),
    biddingMethod: Yup.string().required('Please choose a Bidding Method'),
  };
  return Yup.object().shape(schemaAttributes);
};

const conditionOfSaleSchema = () => {
  let schemaAttributes = {
    initialsUS: Yup.string().min(2, 'Too Short!'),
    // .test(
    //   'initialsUS',
    //   'Initials Does not match first and last name provided',
    //     function (value) {
    //       const { firstName, lastName, conditionsOfSale } = this.parent;
    //       if (value) {
    //         return (
    //           value.toUpperCase() ===
    //           getInitials(firstName, lastName).toUpperCase()
    //         );
    //       } else {
    //         if (conditionsOfSale.includes('US Conditions of sale')) {
    //           return false;
    //         } else {
    //           return true;
    //         }
    //       }
    //     }
    //   ),
    // initialsUK: Yup.string()
    //   .min(2, 'Too Short!')
    //   .test(
    //     'initialsUk',
    //     'Initials Does not match first and last name provided',
    //     function (value) {
    //       const { firstName, lastName, conditionsOfSale } = this.parent;
    //       if (value) {
    //         return (
    //           value.toUpperCase() ===
    //           getInitials(firstName, lastName).toUpperCase()
    //         );
    //       } else {
    //         if (conditionsOfSale.includes('UK Conditions of sale')) {
    //           return false;
    //         } else {
    //           return true;
    //         }
    //       }
    //     }
    //   ),
  };
  return Yup.object().shape(schemaAttributes);
};

const uploadingPersonalDocumentsSchema = () => {
  let schemaAttributes = {
    photoIdentificationDocType: Yup.string().required(
      'Document Type is required.'
    ),
    photoIdentificationFile: Yup.mixed()
      .required('A file is required.')
      .test(
        'fileSize',
        'File too large.',
        value => value && value.size <= FILE_SIZE
      )
      .test(
        'fileFormat',
        'Unsupported Format.',
        value => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    additionalIdentificationFile: Yup.mixed()
      .test('fileSize', 'File too large.', value =>
        value ? value.size <= FILE_SIZE : true
      )
      .test('fileFormat', 'Unsupported Format.', value =>
        value ? SUPPORTED_FORMATS.includes(value.type) : true
      ),
  };
  return Yup.object().shape(schemaAttributes);
};

const financialDataSchema = () => {
  let schemaAttributes = {
    bankLetterFile: Yup.mixed()
      .required('A file is required.')
      .test('fileSize', 'File too large.', value =>
        value ? value.size <= FILE_SIZE : true
      )
      .test('fileFormat', 'Unsupported Format.', value =>
        value ? SUPPORTED_FORMATS.includes(value.type) : true
      ),
    bankName: Yup.string().label('Bank Name').min(1, 'Too short').max(128),
    bankZipcode: Yup.string().label('Bank zipCode').min(1, 'Too short').max(20),
    bankBranchOffice: Yup.string().label('Branch office').max(128),
    bankStreetAddress: Yup.string().label('Bank address').max(255),
    bankPhone: Yup.string().label('Bank phone').max(25),
    biddingLimits: Yup.string()
      .label('Bidding Limits')
      .required('Requested Bidding Limits is required.')
      .min(1, 'Too short')
      .max(18),
    acceptFinancialTerms: Yup.boolean().oneOf(
      [true],
      'Please Accept Terms and Conditions'
    ),
  };
  return Yup.object().shape(schemaAttributes);
};

const telephoneBiddingSchema = () => {
  let schemaAttributes;

  schemaAttributes = {
    primaryPhone: Yup.string().required('Phone is required').nullable(),
    secondaryPhone: Yup.string().notRequired(),
  };

  return Yup.object().shape(schemaAttributes);
};

const paymentSchema = () => {
  let schemaAttributes;

  schemaAttributes = {
    firstName0: Yup.string()
      .label('First Name')
      .required('First Name is required.')
      .min(1, 'Too short')
      .max(20),
    lastName0: Yup.string()
      .label('Last Name')
      .required('Last Name is required.')
      .min(1, 'Too short')
      .max(20),
    aboutYouAddress01: Yup.string()
      .label('Address1')
      .required('Address 1 is required.')
      .min(1, 'Too short')
      .max(100),
    aboutYouAddress02: Yup.string().label('Address2').min(0).max(100),
    city0: Yup.string()
      .label('City')
      .required('City is required.')
      .min(1, 'Too short')
      .max(40),
    state0: Yup.string().required('State is required.'),
    country0: Yup.string().required('Country is required.'),
    zipcode0: Yup.string()
      .label('Zipcode')
      .required('Zipcode is required.')
      .min(1, 'Too short')
      .max(10),
    shipping: Yup.string().required('Shipping method is required'),
    shippingAddress: Yup.string()
      .label('Shipping Address1')
      .required('Address 1 is required.')
      .min(1, 'Too short')
      .max(100),
    shippingAddress2: Yup.string().label('Shipping Address2').min(0).max(100),
    shippingCity: Yup.string()
      .label('Shipping City')
      .required('City is required.')
      .min(1, 'Too short')
      .max(40),
    shippingState: Yup.string()
      .label('Shipping State')
      .required('State is required.'),
    shippingCountry: Yup.string()
      .label('Shipping Country')
      .required('Country is required.'),
    shippingZipcode: Yup.string()
      .label('Shipping Zipcode')
      .required('Zipcode is required.')
      .min(1, 'Too short')
      .max(10),
  };
  return Yup.object().shape(schemaAttributes);
};

const kioskPaymentSchema = () => {
  let schemaAttributes;

  schemaAttributes = {
    kioskPaymentSucceeded: Yup.boolean().oneOf(
      [true],
      'Please swipe your card to finish your payment'
    ),
  };

  return Yup.object().shape(schemaAttributes);
};

export function getRegisterToBidSchema() {
  return {
    [steps.USER_INFO]: userInfoSchema,
    [steps.AUCTIONS_SELECTION]: auctionsSelectionSchema,
    [steps.CONDITION_OF_SALE]: conditionOfSaleSchema,
    [steps.UPLOADING_PERSONAL_DOCUMENTS]: uploadingPersonalDocumentsSchema,
    [steps.FINANCIAL_DATA]: financialDataSchema,
    [steps.PAYMENT]: paymentSchema,
    [steps.TELEPHONE_BIDDING_DETAILS]: telephoneBiddingSchema,
    [steps.EMAIL]: emailSchema,
    [steps.KIOSK_PAYMENT]: kioskPaymentSchema,
  };
}

const getInitials = (firstName, lastName) => {
  return `${firstName.match(/\b(\w)/g).join('')}${lastName
    .match(/\b(\w)/g)
    .join('')}`;
};

export const getUpdateProfileData = (values, id) => {
  const data = {
    id: id,
    address1: values.aboutYouAddress1,
    address2: values.aboutYouAddress2,
    birthdate: moment(values.dateOfBirth).format('YYYY-MM-DD'),
    city: values.city,
    country: values.country,
    firstName: values.firstName,
    lastName: values.lastName,
    mainPhone: values.phone,
    state: values.state,
    zipcode: values.zipcode,
    company: values.company,
    email: values.email,
  };
  return data;
};
