import React from 'react';
import FloatingLabel from 'floating-label-react';
import NumberFormat from 'react-number-format';
import { Field } from 'formik';

const CustomDatePicker = ({
  name,
  placeholderText,
  floating = true,
  disabled = false,
  onKeyDown,
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <div onKeyDown={onKeyDown}>
            <div className="widthSet date-picker">
              <NumberFormat
                format="##/##/####"
                placeholder={placeholderText}
                mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                customInput={floating ? FloatingLabel : null}
                name={field.name}
                disabled={disabled}
                className={`${meta.touched && meta.error ? 'error' : ''}`}
                {...field}
              />
              {meta.touched && meta.error && (
                <p className="error-message">{meta.error}</p>
              )}
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default CustomDatePicker;
