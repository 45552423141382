import React from 'react';
import PropTypes from 'prop-types';
import { textPropType } from 'shared/propTypes';

const ItemList = ({ data }) => (
  <>
    <div>
      <p className="sec">
        <span className="text-subdued">{data.subTitle}</span>
        <span>{data.cost}</span>
      </p>
      <p className="text-subdued">{data.date}</p>
    </div>
    <br />
  </>
);
ItemList.propTypes = {
  handleSelect: PropTypes.func,
  data: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    cost: PropTypes.string,
    date: textPropType,
  }),
};
export default ItemList;
