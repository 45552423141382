import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useAuthenticatedQuery } from 'services/graphql/hooks';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import ProfileHeader from 'features/account/profileHeader';
import TableWithHeader from './TableWithHeader';
import styles from './registrationHistory.module.scss';

import { GET_USER_REGISTERED_CAMPAIGNS_DETAILS } from 'services/graphql/queries/profile';

const RegistrationHistory = () => {
  const { loading, error, data } = useAuthenticatedQuery(
    GET_USER_REGISTERED_CAMPAIGNS_DETAILS
  );
  const [upcomingCampaigns, setUpcomingCampaigns] = useState([]);
  const [pastCampaigns, setPastCampaigns] = useState([]);

  useEffect(() => {
    if (!data) return;
    const campaigns = data.userRegisteredCampaigns?.registeredCampaigns ?? [];
    const filteredCampaigns = campaigns
      .filter(campaign => campaign.auctionName)
      .map(campaign => {
        const { auctionDates, registrationDate } = campaign;
        const formattedRegistrationDate = registrationDate
          ? formatDate(registrationDate)
          : '-';
        const formattedStartDate =
          auctionDates && auctionDates.auctionStartDate
            ? formatDate(auctionDates.auctionStartDate)
            : '-';
        return {
          ...campaign,
          registrationDate: formattedRegistrationDate,
          startDate: formattedStartDate,
        };
      });
    const [past, upcoming] = filteredCampaigns.reduce(
      ([past, upcoming], c) =>
        c.auctionDates?.auctionEndDate > new Date().toISOString()
          ? [past, [...upcoming, c]]
          : [[...past, c], upcoming],
      [[], []]
    );

    upcoming.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    past.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    setPastCampaigns(past);
    setUpcomingCampaigns(upcoming);
  }, [data]);

  const tableColumns = [
    {
      name: 'Auction',
      key: 'auctionName',
    },
    {
      name: 'Auction Date',
      key: 'startDate',
    },
    {
      name: 'Date Registered',
      key: 'registrationDate',
    },
    {
      name: 'Bidding Method',
      key: 'biddingMethod',
    },
  ];
  return (
    <Layout>
      <SEO title="Registration History" />
      <div className={styles.historyBody}>
        <div>
          <div className={styles.historySection}>
            <ProfileHeader />
          </div>
          <div className={styles.heading}>
            <h1>Registration History</h1>
            {loading ? (
              <Spinner />
            ) : error ? (
              <Alert color="danger" msg={error.message} />
            ) : (
              <>
                <TableWithHeader
                  campaigns={upcomingCampaigns}
                  headerText="Upcoming Auctions"
                  columns={tableColumns}
                />
                <TableWithHeader
                  campaigns={pastCampaigns}
                  headerText="Past Auctions"
                  columns={tableColumns}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RegistrationHistory;

const formatDate = date => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};
