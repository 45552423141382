import { useStaticQuery, graphql } from 'gatsby';

export const useKioskSettings = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allContentfulWebKiosk {
          nodes {
            landingPageImage {
              fluid(maxWidth: 1800, maxHeight: 620, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
            ctaCopy
          }
        }
      }
    `
  );
  return query.allContentfulWebKiosk?.nodes
    ? query.allContentfulWebKiosk?.nodes[0]
    : {};
};
