import Alert from 'components/Alert';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import Spinner from 'components/Spinner';
import _ from 'lodash';
import React, { useState } from 'react';
import { useAuthenticatedQuery } from 'services/graphql/hooks';
import { GET_FOLLOWED_VEHICLES } from 'services/graphql/queries/vehicles-catalogue';
import { useCheckNotAuthenticatedUsers } from 'shared/helpers/Auth';
import styles from './recentlyViewed.module.scss';
import ProfileHeader from 'features/account/profileHeader';
import List from 'features/account/list';
import { GridList } from '../../../static/svg/GridList';
import { ListIcon } from '../../../static/svg/ListIcon';
import CustomPagination from 'components/Pagination';
import { useMediaQuery } from 'react-responsive';
export const RecentlyViewed = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const isGrid = useMediaQuery({ maxWidth: 1092 });
  const [list, setList] = useState(false);
  const changePage = () => {};
  const changeListView = () => {
    setList(true);
  };
  const changeGridView = () => {
    setList(false);
  };
  const isNotAuthenticated = useCheckNotAuthenticatedUsers();
  const { loading, error, data } = useAuthenticatedQuery(
    GET_FOLLOWED_VEHICLES,
    {
      skip: isNotAuthenticated,
    }
  );

  const vehiclesDuplicates = data?.followedVehicles?.vehicles;

  const sortByLatestAuctionDate = (a, b) => {
    let comparison = 0;
    if (new Date(a.auctionStartDate) < new Date(b.auctionStartDate)) {
      comparison = 1;
    } else if (new Date(a.auctionStartDate) > new Date(b.auctionStartDate)) {
      comparison = -1;
    }
    return comparison;
  };

  const vehicles =
    vehiclesDuplicates?.length > 0
      ? _.uniqBy(
          [...vehiclesDuplicates].sort(sortByLatestAuctionDate),
          'salesForceId'
        )
      : [];
  return (
    <Layout>
      <SEO title="Following" />
      {loading && <Spinner />}
      {error && <Alert color="danger" msg={error.message} />}
      <div className={styles.followingBody}>
        <div>
          {!isGrid && (
            <div className={styles.followingSection}>
              <ProfileHeader />
            </div>
          )}
          <div className={styles.heading}>
            {/* <div className={styles.recentlyViewedHeader}>
              <h1>Recently Viewed</h1>
              {!isMobile && (
                <div className={styles.icons}>
                  <GridList handleClick={changeGridView} active={!list} />
                  <ListIcon handleClick={changeListView} active={list} />
                </div>
              )}
            </div> */}
            {vehicles?.length > 0 && (
              <>
                <List vehicles={vehicles} recentlyViewed={true} list={list} />
              </>
            )}
            {vehicles?.length === 0 && !loading && (
              <em className="noResultsMsg d-flex align-items-center justify-content-center h-100">
                "No recently viewed vehicles found"
              </em>
            )}
            <div>
              <CustomPagination
                currentPage={1 || 1}
                totalPage={2 || 1}
                itemsCountPerPage={10}
                onPageChange={changePage}
                recentlyViewed={true}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
