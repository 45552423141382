import * as React from 'react';

function Check(props) {
  const color = props.color || '#1C5BAE';
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.57825 9.642L15.2202 0L16.5005 1.28025L5.57825 12.2025L0.5 7.12575L1.78025 5.8455L5.57825 9.642Z"
        fill="white"
      />
    </svg>
  );
}

export default Check;
