import React from 'react';
import { Fade } from 'reactstrap';
import styles from './ConfirmAlert.module.scss';

const ConfirmAlert = ({ show, message }) => {
  return (
    <Fade in={show}>
      <div className={styles.confirmAlertWrapper}>
        <span className={styles.confirmAlertText}>{message}</span>
      </div>
    </Fade>
  );
};

ConfirmAlert.defaultProps = {
  show: false,
};

export default ConfirmAlert;
