import React from 'react';
import PropTypes from 'prop-types';
import searchOrLinkDevice from 'static/images/search-or-link-device.png';
import styles from './SearchOrLink.module.scss';

const SearchOrLink = ({ selectedDevice }) => {
  return (
    <>
      <div className={styles.state}>
        {selectedDevice
          ? `Linking #${selectedDevice.serial_number}`
          : 'Searching....'}
      </div>
      <img
        src={searchOrLinkDevice}
        alt={selectedDevice ? 'linking' : 'searching'}
        className={styles.image}
      />
    </>
  );
};

SearchOrLink.propTypes = {
  selectedDevice: PropTypes.shape({
    id: PropTypes.string,
    serial_number: PropTypes.string,
  }),
};

export default SearchOrLink;
