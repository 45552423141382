import * as Yup from 'yup';

const INITIAL_VALUES = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const getPasswordSchema = () => {
  return Yup.string()
    .required('Password is required.')
    .min(8, 'Password must be 8 characters minimum.')
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)[a-zA-Z\d]/,
      'Password must contain at least one lower case, upper case, and number'
    )
    .nullable();
};

const SCHEMA = Yup.object().shape({
  currentPassword: getPasswordSchema(),
  newPassword: getPasswordSchema(),
  confirmPassword: getPasswordSchema().oneOf(
    [Yup.ref('newPassword'), null],
    'Confirm password must match new passwords'
  ),
});

export { INITIAL_VALUES, SCHEMA };
