import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import GCO_Logo from 'static/svg/GCO_Logo.svg';
import Button from 'components/Button';
import { generateFluidImage } from 'utils/image';
import { KIOSK_REGISTER_PATH } from 'utils/pathsConstants';
import styles from './LandingPage.module.scss';
import { useKioskSettings } from 'services/graphql/hooks/Kiosk';
import SEO from 'components/seo';
import { navigate } from 'gatsby';
import FailureModel from 'components/FailureModel';

const LandingPage = ({ location }) => {
  const settings = useKioskSettings();

  const { landingPageImage: kioskPhoto, ctaCopy } = settings;
  useEffect(() => {
    if (!localStorage.getItem('connectedReaderSN')) {
      navigate('/auth/link-kiosk', {
        state: { navigateToKiosk: true },
      });
    }
  }, []);

  const state = location?.state || {};
  return (
    <>
      <SEO title="Kiosk Registration" />
      <BackgroundImage
        fluid={generateFluidImage(
          1800,
          kioskPhoto?.file?.url,
          90,
          kioskPhoto?.file?.details?.width,
          kioskPhoto?.file?.details?.height
        )}
        className={styles.background}
      >
        <div className={styles.wrapper}>
          <div className={styles.data}>
            <p className={styles.dataText}>Welcome to</p>
            <Link to="/" className={styles.logoWrapper}>
              <div
                className={styles.logo}
                style={{ backgroundImage: `url(${GCO_Logo})` }}
              ></div>
            </Link>
            <Button
              type={`app-transparent-white-button ${styles.dataButton}`}
              linkAsButton={true}
              path={`/${KIOSK_REGISTER_PATH}`}
            >
              {ctaCopy}
            </Button>
          </div>
        </div>
      </BackgroundImage>
      {state.failedVerification && <FailureModel />}
    </>
  );
};

export default LandingPage;
