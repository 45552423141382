import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Typeahead } from 'react-bootstrap-typeahead';
import './searchInput.scss';

const SearchInput = ({
  placeholder,
  options,
  values,
  fieldName,
  onInputChange,
  onChange,
  labelKey,
}) => {
  const selection = values[fieldName] ?? '';

  return (
    <div
      className={`type-ahead-search-input-description ${
        selection ? 'floatingInput' : ''
      }`}
    >
      {selection ? <span className="floatingSpan">{placeholder}</span> : ''}
      <Typeahead
        id={`basic-typeahead-single${fieldName}`}
        labelKey={labelKey}
        onChange={change => {
          if (onChange) onChange(change);
        }}
        onInputChange={change => {
          if (onInputChange) onInputChange(change);
        }}
        options={options}
        placeholder={placeholder}
        selected={[values[fieldName] ?? '']}
        multiple={false}
      />
    </div>
  );
};

export default SearchInput;
