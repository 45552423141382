import React from 'react';
import Table from 'components/Table';
import styles from './registrationHistory.module.scss';

const TableWithHeader = ({ campaigns = [], headerText = '', columns = [] }) => {
  return (
    <>
      <h3>{headerText}</h3>
      {campaigns.length !== 0 ? (
        <Table data={campaigns} columns={columns} />
      ) : (
        <div>
          <em
            className={`noResultsMsg d-flex align-items-center justify-content-center ${styles.emptyTableMsg}`}
          >
            {`No ${headerText}`}
          </em>
        </div>
      )}
    </>
  );
};

export default TableWithHeader;
