import * as React from 'react';

function CheckIcon(props) {
  return (
    <svg height="70px" viewBox="0 0 24 24" width="70px" fill="#000000">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
    </svg>
  );
}

export default CheckIcon;
