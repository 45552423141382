import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Button from 'components/Button';
import styles from './ListDevices.module.scss';

const ListDevices = ({ devices, linkedDevice, search, link, unlink }) =>
  isEmpty(devices) ? (
    <div className={styles.state}>
      No Devices Found
      <Button type="app-primary-button" handleClick={search}>
        Try Again
      </Button>
    </div>
  ) : (
    <>
      <div className={styles.state}>
        {linkedDevice ? 'Device Linked' : 'Devices Found'}
      </div>
      {devices?.map(device => {
        const linked = device.serial_number === linkedDevice?.serial_number;
        return (
          <div className={styles.device} key={device.id}>
            <span
              className={styles.serialNumber}
            >{`#${device.serial_number}`}</span>
            {linked ? (
              <>
                <span className={styles.checkMark}></span>
                <Button type="app-transparent-blue-button" handleClick={unlink}>
                  Unlink
                </Button>
              </>
            ) : (
              <Button
                type="app-primary-button"
                handleClick={() => link(device)}
              >
                Link
              </Button>
            )}
          </div>
        );
      })}
    </>
  );

ListDevices.propTypes = {
  devices: PropTypes.array,
  linkedDevice: PropTypes.shape({
    id: PropTypes.string,
    serial_number: PropTypes.string,
  }),
  search: PropTypes.func,
  link: PropTypes.func,
  unlink: PropTypes.func,
};

export default ListDevices;
