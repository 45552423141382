import React from 'react';
import Button from 'components/Button';
import { KIOSK_PATH } from 'utils/pathsConstants';
import styles from './KioskConfirmation.module.scss';
import CheckIcon from 'svg/CheckIcon';
const KioskConfirmation = () => {
  return (
    <div className={styles.KioskConfirmation}>
      <div>
        <div className={styles.lightBlueCircle}>
          <div className={styles.blackCircle}>
            <div className={styles.check}>
              <CheckIcon />
            </div>
          </div>
        </div>
        <h1 className={`heading mt-5`}>Your registration is submitted</h1>
        <p className={`steps-hint hintSize`}>
          Thank you for submitting your bidder registration. You will receive a
          confirmation at the email address provided.
        </p>
        <p className={`steps-hint hintSize mb-5`}>
          Please proceed to the Bidder Registration desk to finalize your
          credentials.
        </p>
      </div>
      <div className="d-flex flex-row-reverse">
        <Button
          type={`app-primary-button ${styles.button}`}
          linkAsButton={true}
          path={`/${KIOSK_PATH}`}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default KioskConfirmation;
