import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import VehicleList from './vehicleList';
import PhoneNumber from 'components/form-elements/PhoneNumber';
import Alert from 'components/Alert';
import Spinner from 'components/Spinner';
import './step.scss';

const Telephone = ({
  values,
  auctions,
  setFieldValue,
  lots = [],
  loading,
  error,
}) => {
  const secondaryPhoneRef = useRef(null);
  const vehicleInputsRef = useRef(null);

  const getAuctionNames = (auctions, index) => {
    const selectedAuction = auctions.find(auction => {
      return auction.objectID === index;
    });
    return `${selectedAuction.name}`;
  };
  return (
    <div className="user-input-form step-6">
      {loading && <Spinner />}
      {error && <Alert color="danger" msg={error.message} />}
      <h1 className="heading">Telephone &amp; Absentee Bidding</h1>

      <div className="section">
        <h2 className="sub-heading mt-0">Contact Information</h2>
        <div className="field-group hintText">
          <p className="text-subdued mb-4 ">
            Absentee and telephone bid requests must be received at least 24
            hours prior to the Auction in which you wish to bid and must be
            accompanied by a Guarantee in an amount and in a form acceptable to
            Gooding & Company, in its sole discretion, in order to be processed.
          </p>
          <div className="field">
            <PhoneNumber
              name="primaryPhone"
              placeholder="Primary Phone Number&#42;"
              label="&#42;"
              onKeyDown={event => {
                if (
                  event.key.toLowerCase() === 'enter' ||
                  event.keyCode === 13
                ) {
                  secondaryPhoneRef.current.children[0].children[0].focus();
                }
              }}
            />
            <PhoneNumber
              name="secondaryPhone"
              placeholder="Secondary Phone Number"
              notRequired={true}
              phoneRef={secondaryPhoneRef}
              onKeyDown={event => {
                if (
                  event.key.toLowerCase() === 'enter' ||
                  event.keyCode === 13
                ) {
                  vehicleInputsRef.current.children[0].children[0].focus();
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className="section">
        <p className="text-subdued">
          NOTE: If left blank, a Gooding & Company staff will reach out to you
          during the week of the auction to confirm the lots you want to bid on.
        </p>
        <h2 className="sub-heading mt-0">Vehicles</h2>
        <div className="field-group"></div>
      </div>
      <div className="section">
        {values.auction.map((item, index) => (
          <VehicleList
            name={getAuctionNames(auctions, item)}
            auctionID={item}
            key={index}
            index={index}
            setFieldValue={setFieldValue}
            lots={lots.filter(lot => lot.auctionId === item)}
            values={values}
            vehicleInputsRef={index === 0 ? vehicleInputsRef : null}
          />
        ))}
      </div>
    </div>
  );
};

Telephone.propTypes = {
  values: PropTypes.shape({
    formValue: PropTypes.string,
  }),
};
export default Telephone;
