import * as React from 'react';

const Icon = ({ solid = true, color = '#000000' }) => (
  <svg width={23} height={23} viewBox="0 0 23 23">
    <path
      fill={color}
      d={
        solid
          ? 'M11.25 11.75c1.563 0 2.89-.547 3.984-1.64 1.094-1.094 1.641-2.422 1.641-3.985 0-1.563-.547-2.89-1.64-3.984C14.14 1.047 12.812.5 11.25.5c-1.563 0-2.89.547-3.984 1.64-1.094 1.094-1.641 2.422-1.641 3.985 0 1.563.547 2.89 1.64 3.984 1.094 1.094 2.423 1.641 3.985 1.641zM22.5 23v-2.813c0-1.68-1.29-3.037-3.867-4.072-2.578-1.035-5.04-1.552-7.383-1.552-2.344 0-4.805.527-7.383 1.582C1.29 17.16 0 18.508 0 20.188V23h22.5z'
          : 'M22.5 24c.406 0 .758-.148 1.055-.445.297-.297.445-.649.445-1.055v-2.25c0-.281-.125-.547-.375-.797s-.5-.422-.75-.515l-.375-.188-6.516-3.094c.938-.75 1.633-1.773 2.086-3.07.453-1.297.68-2.492.68-3.586V5.25c0-1.25-.719-2.438-2.156-3.563C15.156.563 13.617 0 11.977 0c-1.641 0-3.172.563-4.594 1.688C5.96 2.813 5.25 4 5.25 5.25V9c0 1.031.234 2.203.703 3.516.469 1.312 1.172 2.343 2.11 3.093L1.5 18.75a1.58 1.58 0 00-.422.188c-.156.093-.375.273-.656.539C.14 19.742 0 20 0 20.25v2.25c0 .406.148.758.445 1.055.297.297.649.445 1.055.445h21zm0-1.5h-21v-1.547c0-.375.203-.656.61-.844l6.609-3.14c.5-.219.781-.602.844-1.149a1.55 1.55 0 00-.516-1.383c-.719-.562-1.281-1.398-1.688-2.507C6.953 10.82 6.75 9.844 6.75 9V5.25c0-.813.57-1.64 1.71-2.484C9.603 1.922 10.782 1.5 12 1.5c1.25 0 2.438.422 3.563 1.266 1.124.843 1.687 1.671 1.687 2.484V9c0 .875-.188 1.86-.563 2.953-.375 1.094-.921 1.938-1.64 2.531-.438.344-.625.79-.563 1.336.063.547.344.946.844 1.196l6.516 3.093h.047c.406.125.609.422.609.891v1.5z'
      }
    />
  </svg>
);

export default Icon;
