import React from 'react';
import Lock from 'static/images/lock.png';
import Visa from 'static/images/visa.png';
import MasterCard from 'static/images/masterCard.png';
import Discover from 'static/images/discover.png';
import AmericanExpress from 'static/images/americanExpress.png';
import styles from './Stripe.module.scss';

const PaymentIcons = () => {
  return (
    <div className="d-flex align-items-center">
      <div
        className={`${styles.paymentIcon} ${styles.lockIcon}`}
        style={{ backgroundImage: `url(${Lock})` }}
      ></div>
      <div
        className={styles.paymentIcon}
        style={{ backgroundImage: `url(${Visa})` }}
      ></div>
      <div
        className={styles.paymentIcon}
        style={{ backgroundImage: `url(${MasterCard})` }}
      ></div>
      <div
        className={styles.paymentIcon}
        style={{ backgroundImage: `url(${Discover})` }}
      ></div>
      <div
        className={styles.paymentIcon}
        style={{ backgroundImage: `url(${AmericanExpress})` }}
      ></div>
    </div>
  );
};

export default PaymentIcons;
