import React, { useEffect } from 'react';
import TextInput from 'components/form-elements/TextInput';
import Footer from 'privatePages/OnlineRegistration/Footer';
import { steps } from 'utils/registrationStepsConstants';
import { navigate } from 'gatsby';
import { KIOSK_PATH } from 'utils/pathsConstants';
import { SLUGS } from 'utils/webPageTypesConstants';
import styles from './EmailStep.module.scss';
import { GET_KIOSK_BIDDER_DATA } from 'services/graphql/queries/register-to-bid/kiosk';
import { useAuthenticatedLazyQuery } from 'services/graphql/hooks';
import { getRegisterToBidInitialValues } from 'components/utils/formUtils';
import { checkTrustedBuyerStatus } from 'privatePages/OnlineRegistration/register-to-bid.utils';

const EmailStep = ({
  setStep,
  isValid,
  dispatchChevronStep,
  dispatchPrevSteps,
  slugs,
  isKiosk,
  setTransparentLoader,
  values,
  setUserInitialValues,
  setAlertError,
  setBidderSalesforceId,
  nextButtonRef,
}) => {
  const [getBidderData, { data, loading, error }] = useAuthenticatedLazyQuery(
    GET_KIOSK_BIDDER_DATA,
    {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    }
  );

  async function handleSubmit() {
    if (!isValid) return;

    await getBidderData({
      variables: {
        email: values.email,
      },
      // errorPolicy: 'all',
      // fetchPolicy: 'network-only',
    });
  }

  function transitionToNextStep() {
    dispatchChevronStep('next');

    if (checkTrustedBuyerStatus(data?.bidderData?.buyerStatus))
      setStep(steps.AUCTIONS_SELECTION);
    else setStep(steps.USER_INFO);
    dispatchPrevSteps({ type: 'push', payload: steps.EMAIL });
  }

  function transitionToPrevStep() {
    !isKiosk
      ? navigate(`/${slugs[SLUGS.registerToBid]}`)
      : navigate(`/${KIOSK_PATH}`);
  }

  useEffect(() => {
    setTransparentLoader(loading);
  }, [loading]);

  useEffect(() => {
    if (data?.bidderData) {
      setUserInitialValues(
        getRegisterToBidInitialValues(data.bidderData, isKiosk)
      );
      setBidderSalesforceId(data.bidderData.id);
      transitionToNextStep();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      if (
        error.graphQLErrors &&
        error.graphQLErrors[0].extensions?.code === 'USER_NOT_FOUND'
      ) {
        setUserInitialValues(
          getRegisterToBidInitialValues({ email: values.email }, isKiosk)
        );
        setBidderSalesforceId(null);
        transitionToNextStep();
      } else setAlertError(error.message);
    }
  }, [error]);
  return (
    <>
      <div className="user-input-form">
        <h1 className="heading">REGISTER TO BID</h1>
        <h2 className={styles.subHeader}>
          Enter your email address to get started.
        </h2>
        <div className="section">
          <div className="field-group">
            <div className="field">
              <TextInput
                name="email"
                placeholder="Email Address&#42;"
                disabled={false}
                onKeyDown={event => {
                  if (
                    event.key.toLowerCase() === 'enter' ||
                    event.keyCode === 13
                  ) {
                    nextButtonRef.current.children[0].focus();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer
        handleNext={() => handleSubmit()}
        handleBack={() => transitionToPrevStep()}
        isValid={isValid}
        isKiosk={isKiosk}
        submitButtonText="Next"
        nextRef={nextButtonRef}
      />
    </>
  );
};

export default EmailStep;
