import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { textPropType } from 'shared/propTypes';
import styles from './SelectCard.module.scss';

const BiddingMethodItem = ({
  title,
  price,
  selected,
  handleSelect,
  visible,
  priceRange,
}) => (
  <button
    className={`${styles.gridItem} ${
      selected === title ? styles.selected : ''
    } ${styles.visible} `}
    onClick={() => _.isFunction(handleSelect) && handleSelect(title)}
    disabled={visible}
  >
    <div className={styles.itemContent}>
      <div className={styles.auctionSelectionHeading}>{title}</div>
      <p className="text-secondary">
        <span>&#36;</span>
        {price ? price : priceRange}
      </p>
    </div>
  </button>
);

BiddingMethodItem.propTypes = {
  title: PropTypes.string,
  price: textPropType,
  selected: PropTypes.string,
  handleSelect: PropTypes.func,
  visible: PropTypes.bool,
};

export default BiddingMethodItem;
