import React from 'react';
import { useAuthenticatedQuery } from 'services/graphql/hooks';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import { GET_ASSET_PRESIGNED_DOWNLOAD_URL } from 'services/graphql/queries/assets';

const Files = ({ id }) => {
  const { error, data } = useAuthenticatedQuery(
    GET_ASSET_PRESIGNED_DOWNLOAD_URL,
    {
      variables: {
        key: decodeURIComponent(id),
      },
    }
  );
  if (data?.GetDownloadURL?.url)
    window.location.replace(data.GetDownloadURL.url);
  return (
    <div>
      {error ? (
        <Layout>
          <SEO title="Files" />
          <Alert color="danger" msg={error.message} />
        </Layout>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Files;
