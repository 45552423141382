import React from 'react';
import Button from 'components/Button';
import TextInput from 'components/form-elements/TextInput';
import Select from 'components/form-elements/Select';
import DatePicker from 'components/form-elements/DatePicker';
import PhoneNumber from 'components/form-elements/PhoneNumber';
import styles from './profileForm.module.scss';
import LocationInformation from './locationInformation';

const titleData = [
  { value: 'Mr.', label: 'Mr.', id: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.', id: 'Mrs.' },
  { value: 'Miss', label: 'Miss', id: 'Miss' },
  { value: 'Dr.', label: 'Dr.', id: 'Dr.' },
];

const Form = ({ values, setFieldValue, disabled }) => {
  const billingFieldsNames = {
    address1: 'address1',
    address2: 'address2',
    city: 'city',
    state: 'state',
    zipcode: 'zipcode',
    country: 'country',
  };

  const shippingFieldsNames = {
    address1: 'shippingAddress',
    address2: 'shippingAddress2',
    city: 'shippingAddressCity',
    state: 'shippingAddressState',
    zipcode: 'shippingAddressPostalCode',
    country: 'shippingAddressCountry',
  };

  return (
    <div className={styles.profileForm}>
      <h1 className={styles.heading}>Profile</h1>
      <p className={styles.text}>
        Completing & saving your online profile will help auto-populate our
        multiple forms within the site. This may save you time and help your
        experience.
      </p>
      <div className={styles.formSection}>
        <h2 className={styles.subHeading}>Personal Information</h2>
        <div className="row">
          <div className={`col-sm-12`}>
            <div className="row">
              <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
                <Select
                  name="title"
                  data={titleData}
                  placeholder="Title"
                  ariaLabel="title"
                />
              </div>
            </div>
          </div>

          <div className={`col-sm-12`}>
            <div className="row">
              <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
                <TextInput name="firstName" placeholder="First Name&#42;" />
              </div>
              <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
                <TextInput name="lastName" placeholder="Last Name&#42;" />
              </div>
            </div>
            <div className="row">
              <div className={`${styles.datePicker} col-sm-12 col-md-6`}>
                <DatePicker
                  name="birthdate"
                  placeholderText="Date of Birth&#42;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.formSection}>
        <h2 className={styles.subHeading}>Phone Numbers</h2>
        <div className="row">
          <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
            <PhoneNumber
              name="mainPhone"
              placeholder="Phone&#42;"
              label="&#42;"
            />
          </div>
          <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
            <PhoneNumber name="mobilePhone" placeholder="Phone" />
          </div>
        </div>
      </div>
      <LocationInformation
        country={values.country}
        state={values.state}
        setFieldValue={setFieldValue}
        title="Billing Information"
        placeholderPrefix="Billing"
        fieldsNames={billingFieldsNames}
      />
      <LocationInformation
        country={values.shippingAddressCountry}
        state={values.shippingAddressState}
        setFieldValue={setFieldValue}
        title="Shipping Information"
        placeholderPrefix="Shipping"
        fieldsNames={shippingFieldsNames}
      />
      <Button
        disabled={disabled}
        type={`${styles.saveChange} app-primary-button`}
        btnType="submit"
      >
        Save Changes
      </Button>
      <hr className="mt-5 mb-4" />
    </div>
  );
};

export default Form;
