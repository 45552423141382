import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Button from 'components/Button';
import TextInput from 'components/form-elements/TextInput';
import CheckboxInput from 'components/form-elements/CheckboxInput';
import { SCHEMA, getInitialValues } from 'components/utils/changeEmail';
import ConfirmAlert from 'components/ConfirmAlert';
import Spinner from 'components/Spinner';
import { UPDATE_MESSAGES } from 'utils/UpdateMessages';
import styles from '../Change.module.scss';
import { useAuthenticatedMutation } from 'services/graphql/hooks';
import {
  CHANGE_EMAIL,
  UPDATE_EMAIL_OPTOUT,
} from 'services/graphql/queries/profile';
import Alert from 'components/Alert';
import { useAuth0 } from '@auth0/auth0-react';

const ChangeEmail = ({ profile }) => {
  const { loginWithRedirect } = useAuth0();
  const [changeEmail, { error, loading }] = useAuthenticatedMutation(
    CHANGE_EMAIL
  );
  const [redirectionLoader, setRedirectionLoader] = useState(false);

  const [
    emailOptOut,
    { error: emailOptOutError, loading: emailOptOutLoading },
  ] = useAuthenticatedMutation(UPDATE_EMAIL_OPTOUT);

  const [success, setSuccess] = useState(false);
  const showSuccessMessage = () => {
    setSuccess(true);
    setTimeout(() => setSuccess(false), 7000);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const { currentPassword, newEmail } = values;
    await changeEmail({
      variables: {
        currentPassword: currentPassword,
        newEmail: newEmail,
        username: profile.email,
      },
    }).then(async () => {
      showSuccessMessage();
      resetForm({ ...getInitialValues(profile) });
      setRedirectionLoader(true);
    });
  };

  useEffect(() => {
    if (redirectionLoader) {
      setTimeout(() => {
        setRedirectionLoader(false);
        localStorage.setItem(
          'currentRoute',
          `${window.location.pathname}${window.location.search}`
        );
        loginWithRedirect();
      }, 2000);
    }
  }, [redirectionLoader]);

  const showError = error || emailOptOutError;
  return (
    <>
      {showError && <Alert color="danger" msg={showError?.message} />}
      {(loading || emailOptOutLoading || redirectionLoader) && <Spinner />}
      <Formik
        validateOnMount
        initialValues={{
          ...getInitialValues(profile),
        }}
        validationSchema={SCHEMA}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, isValid, isSubmitting, touched }) => {
          return (
            <Form className="d-flex flex-column justify-content-between h-100">
              <div>
                <h2 className={styles.subHeading}>Change Email</h2>
                <div className={`${styles.fieldSpace}`}>
                  <TextInput
                    name="email"
                    placeholder="Current Email&#42;"
                    disabled={true}
                  />
                </div>
                <div className={`${styles.fieldSpace}`}>
                  <TextInput name="newEmail" placeholder="New Email&#42;" />
                </div>
                <div className={`${styles.fieldSpace}`}>
                  <TextInput
                    name="currentPassword"
                    placeholder="Current Password&#42;"
                    type="password"
                  />
                </div>
              </div>
              <Button
                disabled={
                  !isValid || isSubmitting || Object.keys(touched).length === 0
                }
                type={`${styles.updateBtn} app-primary-button`}
                btnType="submit"
              >
                Update
              </Button>
              <div className={`${styles.fieldSpace}`}>
                <div className={styles.termsWrapper}>
                  <CheckboxInput
                    name="emailOptOut"
                    title="I would like to opt-out of promotional emails"
                    handleSelect={() => {
                      emailOptOut({
                        variables: {
                          user: { emailOptOut: !values.emailOptOut },
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <ConfirmAlert message={UPDATE_MESSAGES['email']} show={success} />
    </>
  );
};

export default ChangeEmail;
