import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Link, navigate } from 'gatsby';
import SEO from 'components/seo';
import Layout from 'components/Layout';
import GoodingContacts from 'components/GoodingContacts';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import UserInfo from '../Checkout/UserInfo';
import StripeInfo from '../Checkout/StripeInfo';
import {
  VALIDATION_SCHEMA,
  LIST_VALIDATION_SCHEMA,
  UK_INITIAL_VALUES,
} from 'components/utils/paymentForm';
import Modal from 'components/Modal';
import Alert from 'components/Alert';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { PAYMENT_CHECKOUT } from 'services/graphql/queries/Checkout';
import styles from '../Checkout/Checkout.module.scss';
import {
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from 'services/graphql/hooks';
import { GET_PROFILE_DATA } from 'services/graphql/queries/profile';
import { useAuth0 } from '@auth0/auth0-react';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import { SLUGS } from 'utils/webPageTypesConstants';
import { amplitudeEvents, trackEvent } from 'utils/amplitude';

export const CheckoutForm = ({ location }) => {
  const [stripeData, setStripeData] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [stripeError, setStripeError] = useState(false);
  const { isAuthenticated, user } = useAuth0();
  const slugs = useWebPagesSlugs();
  const localStorageRef =
    typeof localStorage !== `undefined` ? localStorage : null;

  const elements = useElements();
  const stripe = useStripe();

  const [CreateIntent, { loading, error }] = useAuthenticatedMutation(
    PAYMENT_CHECKOUT,
    {
      skipToken: !isAuthenticated,
    }
  );
  const state = location.state || {};
  let cart = state.cart || [];
  const [invoiceWithReferenceId] = useState(!state.cart?.length);
  const handleSubmit = values => {
    trackEvent(amplitudeEvents.SUBMIT_PAYMENT, { email_address: user?.email });
    const {
      amount,
      receiptEmail,
      referenceId,
      name,
      line1,
      line2,
      city,
      country,
      postalCode,
      state,
    } = values;
    const checkoutData = {
      isUKAccount: true,
      amount: Math.round(amount ? +amount * 100 : getTotalPrice(state) * 100),
      receiptEmail,
      shipping: {
        name,
        line1,
        line2,
        city,
        country,
        postalCode,
        state,
      },
      referenceId,
      order: cart.map(item => {
        return {
          name: item.name,
          quantity: item.quantity,
          shipping: item.note,
          price: Math.round(item.price * 100) / 100,
        };
      }),
      orderCatalog: !invoiceWithReferenceId,
    };
    setCartData([]);
    return CreateIntent({
      variables: { checkoutData },
      skipToken: !isAuthenticated,
    }).then(async res => {
      const clientSecret = res.data.CreateIntent.clientSecret;
      return confirmPayment(clientSecret);
    });
  };

  const confirmPayment = async clientSecret => {
    setStripeLoading(true);
    setStripeError(false);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });
    if (payload.error) {
      setStripeLoading(false);
      setStripeError(payload.error.message);
    } else {
      setStripeLoading(false);
      setStripeError(false);
      navigate('/', {
        state: {
          modal: {
            showModal: true,
            title: 'Success! Registration Submitted',
            bodyText:
              'Your payment was successful. A receipt will be sent to the email provided.',
          },
        },
      });
    }
  };

  const { loading: profileLoading, data: profileData } = useAuthenticatedQuery(
    GET_PROFILE_DATA,
    {
      skip: !isAuthenticated,
    }
  );

  const [render, setRender] = useState(1);

  const getTotalPrice = () => {
    if (cart) {
      let sum = 0;
      cart.map(item => {
        return (sum += item.price);
      });
      return sum;
    } else return 0;
  };

  const setCartData = cart => {
    if (localStorageRef) {
      localStorage.setItem('CartData', JSON.stringify(cart));
    }
  };

  const RemoveItem = index => {
    cart.splice(index, 1);
    setCartData(cart);
    setRender(Math.random());
  };

  if (profileData) initializeUserData(UK_INITIAL_VALUES, profileData.profile);

  return (
    <Layout hideFooter={true} hideHeaderItems={true}>
      <SEO title="Checkout" />
      {(loading || stripeLoading || profileLoading) && <Spinner />}
      <div className={styles.checkoutWrapper}>
        <GoodingContacts />
        <div className={styles.formContainer}>
          <h1 className={styles.title}>Invoice Payment</h1>
          <Formik
            initialValues={UK_INITIAL_VALUES}
            validationSchema={
              cart?.length > 0 ? LIST_VALIDATION_SCHEMA : VALIDATION_SCHEMA
            }
            validateOnMount
            onSubmit={values => handleSubmit(values)}
            enableReinitialize
          >
            {props => {
              const { values, setFieldValue, errors } = props;
              return (
                <Form>
                  <UserInfo
                    setFieldValue={setFieldValue}
                    country={values.country}
                    state={values.state}
                    amount={getTotalPrice(state) || values.amount}
                    cart={state.cart || 0}
                    RemoveItem={RemoveItem}
                    isUk={true}
                  />
                  <StripeInfo
                    setStripeValidations={value => setStripeData(value)}
                  />
                  <div className={styles.checkoutFooter}>
                    <p>
                      By clicking “Submit Payment”, you agree to pay the above
                      amount and provide your information to help us process
                      your payment and detect fraud. Please see our{' '}
                      <Link to={`/${slugs[SLUGS.privacyPolicy]}`}>
                        Privacy Policy
                      </Link>{' '}
                      for more information on our data practices.
                    </p>
                  </div>

                  <div className="mt-4">
                    <Button
                      type="app-primary-button"
                      btnType="submit"
                      disabled={
                        Object.keys(errors)?.length > 0 ||
                        stripeData ||
                        (!cart?.length && !invoiceWithReferenceId)
                          ? true
                          : false
                      }
                    >
                      Submit Payment
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {stripeError && (
        <Modal
          title="Payment failed"
          bodyText="Unfortunately, your payment failed. Please try again or attempt with a different credit card."
          isOpen={stripeError}
        />
      )}
      {error && <Alert color="danger" msg={error?.message} />}
    </Layout>
  );
};

const initializeUserData = (defaultValues, userData) => {
  for (const key of Object.keys(defaultValues)) {
    if (userData[key]) defaultValues[key] = userData[key];
  }
  defaultValues.receiptEmail = userData.email ?? '';
  defaultValues.postalCode = userData.zipcode ?? '';
  defaultValues.line1 = userData.address1 ?? '';
  defaultValues.line2 = userData.address2 ?? '';
};
