import React from 'react';
import { navigate } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';
import Spinner from '../Spinner';
import { Roles, RolesKey } from 'utils/RolesConstants';

const KioskAdminRoute = ({ component: Component, location, ...rest }) => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const setCurrentRoute = () => {
    const isBrowser = typeof window !== undefined;
    isBrowser &&
      localStorage.setItem(
        'currentRoute',
        `${window.location.pathname}${window.location.search}`
      );
  };

  if (isLoading) return <Spinner />;

  if (!isAuthenticated && location.pathname !== `/auth/login`) {
    setCurrentRoute();
    navigate('/auth/login');
    return null;
  } else if (
    isAuthenticated &&
    !user.email_verified &&
    location.pathname !== `/auth/login`
  ) {
    setCurrentRoute();
    navigate('/auth/unverified');
    return null;
  } else if (!user[RolesKey].includes(Roles.kioskAdmin)) {
    navigate('/');
  } else {
    return <Component {...rest} location={location} />;
  }

  return null;
};

export default KioskAdminRoute;
