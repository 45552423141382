import React, { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import StripeTerminalContext from 'shared/context/StripeTerminalContext';
import { DEV } from 'shared/helpers/FeatureToggle/constants';
import SEO from 'components/seo';
import Layout from 'components/Layout';
import SearchOrLink from './SearchOrLink';
import ListDevices from './ListDevices';
import styles from './LinkKiosk.module.scss';
import { navigate } from 'gatsby';
import { KIOSK_REGISTER_PATH } from 'utils/pathsConstants';
import Modal from 'components/Modal';

export default ({ location }) => {
  const [devices, setDevices] = useState();
  const [selectedDevice, setSelectedDevice] = useState();
  const [linkedDevice, setLinkedDevice] = useState();
  const [showModal, setShowModal] = useState(false);
  const { stripeTerminal, reconnected } = useContext(StripeTerminalContext);

  useEffect(() => {
    if (stripeTerminal) search();
  }, [stripeTerminal]);

  useEffect(() => {
    if (!isEmpty(devices) && reconnected) {
      setLinkedDevice(
        devices.find(
          d => d.serial_number === localStorage.getItem('connectedReaderSN')
        )
      );
    }
  }, [devices, reconnected]);

  const search = () => {
    const location = process.env.GATSBY_STRIPE_LOCATION;
    const simulator = process.env.GATSBY_STRIPE_SIMULATOR === 'true';
    stripeTerminal
      .discoverReaders({
        simulated: simulator,
        ...(location && { location }),
      })
      .then(({ discoveredReaders, error }) => {
        if (error) {
          console.error('Error when attempting to search devices: ', error);
          setShowModal(true);
        } else {
          setDevices(discoveredReaders);
        }
      });
  };

  const link = device => {
    setSelectedDevice(device);
    stripeTerminal.connectReader(device).then(({ reader, error }) => {
      if (error) {
        console.error('Error when attempting to link device: ', error);
        setShowModal(true);
        setSelectedDevice(undefined);
      } else {
        setLinkedDevice(reader);
        setSelectedDevice(undefined);
        localStorage.setItem('connectedReaderSN', reader.serial_number);
        if (location?.state?.navigateToKiosk)
          navigate('/' + KIOSK_REGISTER_PATH);
      }
    });
  };

  const unlink = () => {
    stripeTerminal.disconnectReader().then(() => {
      setLinkedDevice(undefined);
      localStorage.removeItem('connectedReaderSN');
    });
  };

  const listing =
    devices &&
    !selectedDevice &&
    stripeTerminal.getConnectionStatus() !== 'connecting';

  return (
    <Layout hideFooter>
      <SEO title="Link Kiosk" />
      <div className={styles.container}>
        <h1 className={styles.title}>Link Device</h1>
        <p className={styles.description}>
          Link this iPad with a credit card device. Make sure your wifi is on.
        </p>
        {listing ? (
          <ListDevices
            devices={devices}
            linkedDevice={linkedDevice}
            search={search}
            link={link}
            unlink={unlink}
          />
        ) : (
          <SearchOrLink selectedDevice={selectedDevice} />
        )}
      </div>
      {showModal && (
        <Modal
          title={'Error'}
          bodyText={'Failed to link device'}
          isOpen={showModal}
          disableToggling={false}
        />
      )}
    </Layout>
  );
};
