import React from 'react';
import propTypes from 'prop-types';
import Button from 'components/Button';
import styles from './EmailVerificationCard.module.scss';

const EmailVerificationCard = ({ email, handleResend, isDisabled }) => {
  return (
    <div className={styles.emailCard}>
      <h1 className={styles.title}>Please Verify Your Email Address</h1>
      <p className={styles.mainContent}>
        An email has been sent to <strong>{email}</strong>. Please click on the
        link in your email to verify your email address.
      </p>
      <div className="d-flex align-items-center">
        <p className="m-0">Didn’t get an email?</p>
        <Button
          type={`app-white-link-button ml-1 ${styles.link}`}
          handleClick={handleResend}
          disabled={isDisabled}
        >
          Resend
        </Button>
      </div>
    </div>
  );
};

EmailVerificationCard.defaultProps = {
  email: 'user.email@goodingco.com',
};

EmailVerificationCard.propTypes = {
  email: propTypes.string,
  handleResend: propTypes.func,
};

export default EmailVerificationCard;
