import React, { useEffect, useRef } from 'react';
import { useCountriesStates } from 'shared/hooks/countries';
import Footer from '../Footer';
import Form from './Form';
import { navigate } from 'gatsby';
import { useAuthenticatedMutation } from 'services/graphql/hooks';
import { VERIFY_USER } from 'services/graphql/queries/register-to-bid';
import {
  VERIFY_BIDDER,
  CREATE_USER_IN_SALESFORCE,
} from 'services/graphql/queries/register-to-bid/kiosk';
import { steps } from 'utils/registrationStepsConstants';
import {
  handleVerifyUser,
  createUserInSalesforce,
  handleAdminVerifyUser,
} from '../register-to-bid.utils';
import { KIOSK_PATH } from 'utils/pathsConstants';
import { SLUGS } from 'utils/webPageTypesConstants';
import './step.scss';
import UploadingPersonalDocumentsStep from '../UploadingPersonalDocumentsStep';

export default ({
  values,
  setFieldValue,
  setStep,
  isValid,
  setTransparentLoader,
  setUserInfoUpdated,
  initialValues,
  slugs,
  isKiosk,
  bidderSalesforceId,
  setAlertError,
  setBidderSalesforceId,
  dispatchChevronStep,
  dispatchPrevSteps,
  handleStartOverButton,
  handleDoneClick,
  nextButtonRef,
  setFieldTouched,
  setIsFieldTouched,
}) => {
  const country = values.country;
  const state = values.state;
  const [countries, states] = useCountriesStates(country, state, setFieldValue);
  const [
    verifyUser,
    { loading: verifyUserLoading, error: verifyUserError },
  ] = useAuthenticatedMutation(isKiosk ? VERIFY_BIDDER : VERIFY_USER);

  const [createUser, { loading: createUserLoading }] = useAuthenticatedMutation(
    CREATE_USER_IN_SALESFORCE
  );

  function checkUserInfoIsUpdated() {
    const updated = Object.keys(initialValues).some(
      key =>
        !(
          initialValues[key] instanceof Object ||
          initialValues[key] instanceof Array ||
          initialValues[key] === values[key]
        )
    );
    if (updated) setUserInfoUpdated(true);
  }

  async function handleSubmit() {
    if (!isValid) return;
    values['city'] = values['city']
      .split(' ')
      .map(word => {
        return word.substr(0, 1).toUpperCase() + word.substr(1);
      })
      .join(' ');

    let status;
    if (isKiosk) {
      const userId =
        bidderSalesforceId ??
        (await createUserInSalesforce(
          values,
          createUser,
          setAlertError,
          setBidderSalesforceId
        ));
      status = await handleAdminVerifyUser(values, verifyUser, userId);
    } else status = await handleVerifyUser(values, verifyUser);
    checkUserInfoIsUpdated();
    if (verifyUserError || status !== 'Passed') {
      navigate(isKiosk ? '/auth/kiosk' : `/${slugs[SLUGS.registerToBid]}`, {
        state: { failedVerification: true },
      });
    } else transitionToNextStep();
  }

  function transitionToNextStep() {
    dispatchChevronStep('next');
    setStep(steps.AUCTIONS_SELECTION);
    dispatchPrevSteps({ type: 'push', payload: steps.USER_INFO });
  }

  function transitionToPrevStep() {
    if (!isKiosk) navigate(`/${slugs[SLUGS.registerToBid]}`);
    else {
      dispatchChevronStep('back');
      const lastStep = dispatchPrevSteps({ type: 'pop' });
      setStep(lastStep);
    }
  }

  useEffect(() => {
    setTransparentLoader(verifyUserLoading || createUserLoading);
  }, [verifyUserLoading, createUserLoading]);

  return (
    <>
      <Form
        countries={countries}
        states={states}
        setFieldValue={setFieldValue}
        isKiosk={isKiosk}
        handleDoneClick={handleDoneClick}
        nextButtonRef={nextButtonRef}
      />
      <UploadingPersonalDocumentsStep
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        values={values}
        isKiosk={isKiosk}
      />
      <Footer
        handleNext={() => handleSubmit()}
        handleBack={() => transitionToPrevStep()}
        isValid={isValid}
        isKiosk={isKiosk}
        submitButtonText="Next"
        handleStartOverButton={handleStartOverButton}
        nextRef={nextButtonRef}
      />
    </>
  );
};
