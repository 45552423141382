import React from 'react';

import styles from './table.module.scss';

const Table = ({ data = [], columns = [] }) => {
  return (
    <div className={styles.tabbleWrapper}>
      <table className="table">
        <thead>
          <tr className={styles.row}>
            {columns.map(column => {
              return <th scope="col">{column.name}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((r, index) => {
            return (
              <tr key={index} className={styles.row}>
                {columns.map(column => {
                  return <td>{r[column.key]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
