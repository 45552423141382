import React from 'react';
import { CONTACT_INFO } from './data';
import GoodingLogo from 'static/svg/GCO_Logo_Blue.svg';
import styles from './GoodingContacts.module.scss';

const GoodingContacts = () => {
  const { subTitle, telephone, email, telWithCountryCode } = CONTACT_INFO;
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.logo}
        style={{ backgroundImage: `url(${GoodingLogo})` }}
      ></div>
      <p>{subTitle}</p>

      <div className={styles.footer}>
        <p>
          <a href={`tel:${telWithCountryCode}`}>{telephone}</a>
        </p>
        <p className="footer-email-mobile">
          <a href={`mailto:${email}`}>{email}</a>
        </p>
      </div>
    </div>
  );
};

export default GoodingContacts;
