import React from 'react';

const HeadLineWithParagraphs = ({ headline = '', children }) => {
  return (
    <div className="mb-4">
      <h3>{headline}</h3>
      {children}
    </div>
  );
};

export default HeadLineWithParagraphs;
