import React from 'react';
import TextInput from 'components/form-elements/TextInput';
import NumberInput from 'components/form-elements/NumberInput';
import { amountFormatted } from 'shared/helpers/Currencies';
import { Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from '../CheckoutForms.module.scss';

const PaymentInfo = ({ amount, items, RemoveItem, isUk }) => {
  return (
    <div className={styles.sectionWrapper}>
      <div className="d-flex align-items-center justify-content-between">
        <h2 className={styles.title}>Payment Detail</h2>
        <h2 className={styles.title}>
          Total:{' '}
          {amountFormatted(amount, isUk ? 'GBP' : 'USD') ||
            `${isUk ? '£0.00' : '$0.00'}`}
        </h2>
      </div>
      <div>
        {items ? (
          items.map((item, index) => {
            return (
              <div className={styles.card} key={index}>
                <Card>
                  <span>
                    <h4 className={styles.left}>{item.name}</h4>
                    <div
                      onClick={() => {
                        RemoveItem(index);
                      }}
                      className={styles.vertical}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                    <h4 className={styles.right}>
                      {amountFormatted(item.price)}
                    </h4>
                    <h4 className={styles.right}>QTY: {item.quantity}</h4>
                  </span>
                </Card>
              </div>
            );
          })
        ) : (
          <div className={`${styles.fieldGroup}`}>
            <div className={`${styles.field}`}>
              <TextInput name="referenceId" placeholder="Reference ID&#42;" />
            </div>
            <div className={`${styles.field}`}>
              <NumberInput
                name="amount"
                placeholder={`Amount ${isUk ? '(GBP£)*' : '(USD$)*'}`}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentInfo;
