import * as Yup from 'yup';

const getInitialValues = user => {
  return {
    email: user?.email || '',
    newEmail: '',
    emailOptOut: user?.emailOptOut || false,
    currentPassword: '',
  };
};

const SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Current email is required.')
    .nullable(),
  newEmail: Yup.string()
    .email('Please enter a valid email.')
    .required('New email is required.')
    .nullable(),
  currentPassword: Yup.string().required('Password is required.').nullable(),
});

export { getInitialValues, SCHEMA };
