import React, { useEffect } from 'react';
import UserInfoStep from 'privatePages/OnlineRegistration/UserInfoStep';
import AuctionSelectionStep from 'privatePages/OnlineRegistration/AuctionSelectionStep';
import ConditionOfSaleStep from 'privatePages/OnlineRegistration/ConditionOfSaleStep';
import UploadingPersonalDocumentsStep from 'privatePages/OnlineRegistration/UploadingPersonalDocumentsStep';
import FinancialDataSteps from 'privatePages/OnlineRegistration/FinancialDataSteps';
import PaymentStep from 'privatePages/OnlineRegistration/PaymentStep';
import TelephoneBiddingDetailsStep from 'privatePages/OnlineRegistration/TelephoneBiddingDetailsStep';
import { steps } from 'utils/registrationStepsConstants';
import KioskConfirmation from 'adminPages/Kiosk/Steps/KioskConfirmation';
import EmailStep from 'adminPages/Kiosk/Steps/EmailStep/index.jsx';
import KioskPaymentStep from 'adminPages/Kiosk/Steps/paymentStep';

const MultiStepForm = props => {
  const step = props.step;
  const setStep = props.setStep;

  useEffect(() => {
    window.scrollTo(0, 0);
    props.validateForm();
    props.setTouched({});
  }, [step]);

  switch (step) {
    case steps.USER_INFO:
      return <UserInfoStep {...props} setStep={setStep} />;
    case steps.AUCTIONS_SELECTION:
      return <AuctionSelectionStep setStep={setStep} {...props} />;
    case steps.CONDITION_OF_SALE:
      return <ConditionOfSaleStep {...props} />;
    case steps.UPLOADING_PERSONAL_DOCUMENTS:
      return <UploadingPersonalDocumentsStep {...props} />;
    case steps.KIOSK_CONFIRMATION:
      return <KioskConfirmation {...props} />;
    case steps.FINANCIAL_DATA:
      return <FinancialDataSteps {...props} />;
    case steps.PAYMENT:
      return <PaymentStep {...props} />;
    case steps.TELEPHONE_BIDDING_DETAILS:
      return <TelephoneBiddingDetailsStep {...props} />;
    case steps.EMAIL:
      return <EmailStep {...props} />;
    case steps.KIOSK_PAYMENT:
      return <KioskPaymentStep {...props} />;
    default:
      return null;
  }
};

export default MultiStepForm;
