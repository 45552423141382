import * as moment from 'moment';

const FORMAT = 'MM/DD/YYYY';
const isValidDateOfBirth = value => {
  const currentYear = moment().year();
  const yearShouldStartWith = /^19|20/;
  const actualYear = moment(value).year();

  if (
    new Date(value) === 'Invalid Date' ||
    !yearShouldStartWith.test(actualYear) ||
    actualYear >= currentYear
  ) {
    return false;
  }
  return moment(value, FORMAT).isValid();
};

export { isValidDateOfBirth, FORMAT };
