import React from 'react';
import PropTypes from 'prop-types';
import { textPropType } from 'shared/propTypes';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import styles from './SelectCard.module.scss';

const GridItem = ({
  handleSelect,
  data,
  selected,
  visible,
  isRegistered,
  biddingMethod,
  length,
}) => {
  return (
    <button
      className={`${styles.gridItem} ${
        data.id === selected ? styles.selected : ''
      } ${visible ? styles.visible : ''}`}
      onClick={() => handleSelect(data.id)}
      key={data.id}
      disabled={!visible || isRegistered}
    >
      {visible && isRegistered && (
        <div className={styles.tag}>
          <span
            className={`${styles.tagText} ${
              length > 3 ? styles.smallTagText : ''
            }`}
          >
            Submitted
          </span>
        </div>
      )}
      {!visible && biddingMethod && (
        <div className={styles.tag}>
          <span
            className={`${styles.tagText} ${
              length > 3 ? styles.smallTagText : ''
            }`}
          >
            Not Available For This Bidding Method
          </span>
        </div>
      )}

      {data?.imgSrc && (
        <div
          className={`${styles.itemImage} ${
            (!visible || (visible && isRegistered)) && styles.setOpacity
          }`}
        >
          <GatsbyImage
            image={data.imgSrc}
            title={data.name}
            alt={data.imgDescription}
          />
        </div>
      )}
      <div
        className={`${styles.itemContent} ${
          (!visible || (visible && isRegistered)) && styles.setOpacity
        }`}
      >
        <div className={styles.auctionSelectionHeading}>{data.name}</div>
        <p className="text-secondary">{data.date}</p>
      </div>
    </button>
  );
};

GridItem.propTypes = {
  handleSelect: PropTypes.func,
  visible: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string,
    imgSrc: PropTypes.object,
    name: PropTypes.string,
    date: textPropType,
  }),
};

export default GridItem;
