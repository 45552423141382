import React from 'react';
import moment from 'moment';
import ConditionsOfSale from './ConditionsOfSale';
import TextInput from 'components/form-elements/TextInput';
import './step.scss';

export default ({ country, slugs, isKiosk, nextButtonRef }) => {
  return (
    <div className="text-subdued">
      <p className="steps-hint">Agree & Sign to Continue</p>
      <ConditionsOfSale ConditionsOfSale={country} slugs={slugs} />
      <p>
        By signing below I hereby authorize Gooding & Company to charge
        registration fees to my credit card, and authorize the release of my
        financial information to Gooding & Company for the purpose of verifying
        available credit for purchases at the auction. I acknowledge that I have
        read, understand and agree to be bound by the Conditions of Sale as
        published in the "Terms" section of this online registration system (as
        may be amended and supplemented as provided in the Conditions of Sale),
        which set forth the terms and conditions under which Gooding & Company
        will offer and sell the vehicles in this auction and governs the rights
        and obligations of the parties.
      </p>
      <div className="user-signature">
        <p>Today's date: {moment().format('MM/DD/YY')}</p>
        <div className="initials-wrapper">
          <p>Accepted by (Initials)&#42; </p>
          <TextInput
            name={`initials${country}`}
            placeholder="Your initials&#42;"
            maxLength={5}
            autoFocus={isKiosk ? true : false}
            onKeyDown={event => {
              if (event.key.toLowerCase() === 'enter' || event.keyCode === 13) {
                nextButtonRef.current.children[isKiosk ? 0 : 1].focus();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
