import { useStaticQuery, graphql } from 'gatsby';

export const useRegistrationAuctions = () => {
  const query = useStaticQuery(
    graphql`
      query {
        graphqlaws {
          getAuctions {
            objectID
            date
            eventStatus
            name
            image
            eventAttendanceMode
            auctionMobilityId
            hidePersonalDocuments
            hideFinancialDocuments
            kioskPhoto {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            fluidAuctionImage {
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            subEvents {
              startDate
              endDate
            }
            offers {
              id
              category
              price
              priceCurrency
              acceptedPaymentMethod
              includesObject
              eligibleQuantity
              eligibleCustomerType {
                id
                title
              }
            }
            conditionsOfSaleType
            campaignId
          }
        }
      }
    `
  );
  return {
    auctions: query.graphqlaws.getAuctions || [],
  };
};
