import React from 'react';
import styles from './Payment.module.scss';
import { amountFormatted } from 'shared/helpers/Currencies';

const Item = ({ item }) => {
  return (
    <div className={`row no-gutters ${styles.rowMargin}`}>
      <div className="col-9">
        <div className={styles.bold}>
          {item.map(subItem => subItem.auctionName).join(' & ')}
        </div>
        <div>IN PERSON BIDDING</div>
      </div>
      <div className="col">
        <div className={styles.price}>{amountFormatted(item[0].price)}</div>
      </div>
    </div>
  );
};

export default Item;
